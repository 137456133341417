import React, { useContext, useEffect } from 'react';
import { LoggedInUserContext } from '../contexts/LoggedInUserContext';
import { updateHubspotIdentity } from '../../utils/hubspot';

export default function HubspotIdentity(): JSX.Element {
  const { loggedInUser } = useContext(LoggedInUserContext);

  useEffect(() => {
    updateHubspotIdentity({
      email: loggedInUser?.email || undefined,
      firstName: loggedInUser?.username || undefined,
    });
  }, [loggedInUser]);

  return <></>;
}
