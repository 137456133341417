import translate from 'counterpart';

export const validatePhone = (phone: string): string => {
  const tenDigitNumeric = new RegExp('^\\(\\d{3}\\)\\s\\d{3}-\\d{4}');
  if (phone && !tenDigitNumeric.test(phone)) {
    return translate('fieldMustBeAValidPhone');
  } else {
    return '';
  }
};

export const validateEmail = (email: string): string => {
  // Source: https://www.w3resource.com/javascript/form/email-validation.php
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email && !emailFormat.test(email.trim())) {
    return translate('fieldMustBeAValidEmail');
  } else {
    return '';
  }
};
