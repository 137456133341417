import React from 'react';

export const ConfigContext = React.createContext({
  authDomain: '',
  authClientId: '',
  authAudience: '',
  practiceConnectApiUrl: '',
  loginUrl: '',
  loggingLevel: '',
  environment: '',
  gtmId: '',
  enterpriseRedirectUrl: '',
  gtmAuth: '',
  gtmPreview: '',
  canSkipEnrollment: true,
  setCanSkipEnrollment: (canSkip: boolean) => {},
});
