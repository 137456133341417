import React from 'react';
import './AlertNotification.scss';

export default function AlertNotification({ fill }: { fill?: string }): JSX.Element {
  return (
    <>
      <svg className={'alert-notification'} width="32" height="32" fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <title>Alert</title>
        <path d="M29.432 25.058L16.764 1.308c-.752-1.412-2.776-1.412-3.53 0L.568 25.058C-.142 26.39.824 28 2.334 28h25.334c1.508 0 2.474-1.61 1.764-2.942zM15 24a2 2 0 1 1 .001-4.001A2 2 0 0 1 15 24zm2-6h-4v-8h4v8z" />
      </svg>
    </>
  );
}
