import React, { ReactNode, useState, useEffect } from 'react';
import { ConfigContext } from '../contexts/ConfigContext';
import constants from '../../utils/constants';

interface IProps {
  children: ReactNode;
  // any other props that come into the component
}

interface IConfig {
  authDomain: '';
  authClientId: '';
  authAudience: '';
  practiceConnectApiUrl: '';
  loginUrl: '';
  loggingLevel: '';
  environment: '';
  gtmId: '';
  enterpriseRedirectUrl: '';
  gtmAuth: '';
  gtmPreview: '';
}

export default function Config({ children }: IProps) {
  const [config, setConfig] = useState<IConfig>({
    authDomain: '',
    authClientId: '',
    authAudience: '',
    practiceConnectApiUrl: '',
    loginUrl: '',
    loggingLevel: '',
    environment: '',
    gtmId: '',
    enterpriseRedirectUrl: '',
    gtmAuth: '',
    gtmPreview: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [canSkipEnrollment, setCanSkipEnrollment] = useState(true);

  useEffect(() => {
    fetch('config.json').then((response) => {
      response.json().then((fileConfig) => {
        setConfig(fileConfig);
        constants.AUTH_DOMAIN = fileConfig.authDomain;
        constants.AUTH_CLIENT_ID = fileConfig.authClientId;
        constants.AUTH_AUDIENCE = fileConfig.authAudience;
        constants.PROJECT_CONNECT_API_BASE_URL = fileConfig.practiceConnectApiUrl;
        constants.ENTERPRISE_REDIRECT_URL = fileConfig.enterpriseRedirectUrl;
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        authDomain: config.authDomain,
        authClientId: config.authClientId,
        authAudience: config.authAudience,
        practiceConnectApiUrl: config.practiceConnectApiUrl,
        loginUrl: config.loginUrl,
        loggingLevel: config.loggingLevel,
        environment: config.environment,
        gtmId: config.gtmId,
        enterpriseRedirectUrl: config.enterpriseRedirectUrl,
        gtmAuth: config.gtmAuth,
        gtmPreview: config.gtmPreview,
        canSkipEnrollment,
        setCanSkipEnrollment,
      }}
    >
      {!isLoading && children}
    </ConfigContext.Provider>
  );
}
