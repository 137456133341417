export enum ApiStatusCodes {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
}

export interface ApiErrorResponse {
  message: string;
}

export class PracticeConnectError<T> {
  constructor(public message: string = 'Unknown Error', public code: number = ApiStatusCodes.INTERNAL_SERVER_ERROR, public body?: T) {}
}
