import React from 'react';
import translate from 'counterpart';
import styles from './EnrollmentSearchingForAccount.module.scss';
import { Spot, SpotIcon } from '../../../Spot';

function EnrollmentSearchingForAccount(): JSX.Element {
  return (
    <div className={styles.container} data-automation-id={'searchingForAccountSpinner'}>
      <div className={styles.text}>
        <div className={styles.spinnerContainer}>
          <SpotIcon
            title={'spinner'}
            iconName={Spot.icons.spinner}
            data-automation-id={'findingPracticeLoadingSpinner'}
            className={'spot-loading-spinner spot-loading-spinner--large'}
          />
        </div>
        <div data-automation-id={'enrollmentSearchingAccountTitle'}>{translate('locatingAccount')}</div>
      </div>
    </div>
  );
}

export default EnrollmentSearchingForAccount;
