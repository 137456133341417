import PropTypes from 'prop-types';
import React from 'react';
require('./SpotForm.scss');

SpotForm.propTypes = {
    children: PropTypes.node
};

/**
 * Wrapper for the other form components. Attempts to be spot compliant. Isn't actually a HTML form to allow greater
 * flexibility in what happens when you click buttons or try to submit.
 */
export default function SpotForm(props) {
    return (
        <div className={'custom-form'}>
            <div className="spot-form">{props.children}</div>
        </div>
    );
}
