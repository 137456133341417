const constants = {
  MINIMUM_SCREEN_SIZE_RESPONSIVE: 768,
  MINIMUM_SCREEN_SIZE_POPOVER: 768,
  MINIMUM_SCREEN_XL_POPOVER: 860,
  PROJECT_CONNECT_API_BASE_URL: process.env.REACT_APP_PROJECT_CONNECT_API || '',
  HOSTED_LOGIN_URL_CFS_AUTH: process.env.REACT_APP_LOGIN_URL || '',
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN || '',
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
  LOCAL_STORAGE_APPLICATION_ID: 'idexx-pc-application',
  AUTH_AUDIENCE: process.env.REACT_APP_AUTH_AUDIENCE || '',
  SESSION_STORAGE: {
    LOGGED_IN_USER: 'loggedInUser',
    PRACTICE_USERS: 'practiceUsers',
  },
  UNKNOWN_APPLICATION: 'UNKNOWN',
  APPLICATIONS: ['communicator', 'enterprise'],
  SUPPORT_PHONE_HREF: 'tel:+18554255207',
  SUPPORT_PHONE_TEXT: '855-425-5207',
  LOCAL_STORAGE_LOCKOUT_KEY: 'practiceLockoutTime',
  ENTERPRISE_REDIRECT_URL: '',
  SAFARI_NOTIFICATION_KEY: 'safariReadAndAccepted',
};

export default constants;
