import React from 'react';
import translate from 'counterpart';
import { SpotIcon, Spot } from '../../../../../../../Spot';

require('./EulaConfirmationPageFooter.scss');

interface IProps {
  submitFunction: () => void;
  buttonText?: string;
}

function EulaConfirmationPageFooter({ submitFunction, buttonText }: IProps) {
  const handleSubmit = () => {
    submitFunction();
  };

  return (
    <div className={'enrollment-eula-confirmation-footer-container'}>
      <div className={'confirmation-content-container'}>
        <div className={'confirmation-title'} data-automation-id={'enrollmentConfirmationTextTitle'}>
          {translate('eulaConfirmationTextTitle')}
        </div>
        <div className={'confirmation-text'} data-automation-id={'enrollmentConfirmationText'}>
          {translate('eulaConfirmationText')}
        </div>
        <div className={'enrollment-eula-confirmation-footer-button-container'}>
          <SpotIcon
            fill={'white'}
            title={'confirmationButton'}
            iconName={Spot.icons.back}
            data-automation-id={'confirmationCompleteButton'}
            className={'confirmation-arrow-button'}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

export default EulaConfirmationPageFooter;
