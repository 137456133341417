import PropTypes from 'prop-types';
import React from 'react';

require('./SpotFormColumnGroup.scss');

SpotFormColumnGroup.propTypes = {
    children: PropTypes.node
};

/**
 * Wrapper for form columns
 */
export default function SpotFormColumnGroup({ children }) {
    return <div className="spot-form-column-group">{children}</div>;
}
