import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { ConfigContext } from '../contexts/ConfigContext';
import * as jwt from 'jsonwebtoken';
import CONSTANTS from '../../utils/constants';
import { UserModel } from '../../services/http/practiceConnect/user/userModel';
import { TokenFactory } from '../../services/http/practiceConnect/practiceConnectApi';
import { LoggedInUserContext } from '../contexts/LoggedInUserContext';
import { getOrCreateMyUser } from '../../services/http/practiceConnect/user/userService';
interface IProps {
  children: ReactNode;
}

function AuthProvider({ children }: IProps) {
  const { authClientId, authDomain, authAudience } = useContext(ConfigContext);

  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      redirectUri={`${window.location.origin}/login`}
      audience={authAudience}
      useRefreshTokens={true}
      scope={'openid'}
    >
      <Auth>{children}</Auth>
    </Auth0Provider>
  );
}

function Auth({ children }: any) {
  const [loggedInUser, setLoggedInUser] = useState<UserModel | null>(null);
  const { loginUrl } = useContext(ConfigContext);
  const { getAccessTokenSilently, isAuthenticated, logout, isLoading } = useAuth0();
  const { authAudience } = useContext(ConfigContext);

  useEffect(() => {
    let isLoggingIn = false;
    const tokenFactory = TokenFactory.getInstance();

    if ((window as any).Cypress) {
      tokenFactory.setTokenMethod(() => Promise.resolve(localStorage.getItem('auth0Cypress')!));
    } else {
      tokenFactory.setTokenMethod(getAuthToken);
    }

    (async () => {
      if (isAuthenticated && !isLoggingIn) {
        isLoggingIn = true;
        const tokenPayload = jwt.decode(await tokenFactory.getBearerToken());

        const { 'https://www.idexx.com/claims/email': email, 'https://www.idexx.com/claims/email': username } =
          tokenPayload as jwt.JwtPayload;
        return getOrCreateMyUser(email, username)
          .then(storeLoggedInUser)
          .then(() => (isLoggingIn = false))
          .catch(console.error);
      }
    })();

    setLoggedInUser(JSON.parse(sessionStorage.getItem(CONSTANTS.SESSION_STORAGE.LOGGED_IN_USER) as string));
  }, [getAccessTokenSilently, isAuthenticated]);

  async function getAuthToken() {
    return await await getAccessTokenSilently({
      audience: authAudience,
      scope: 'openid',
    });
  }

  async function storeLoggedInUser(user: UserModel) {
    const nullSafeUser = user || { error: true };
    sessionStorage.setItem(CONSTANTS.SESSION_STORAGE.LOGGED_IN_USER, JSON.stringify(nullSafeUser));
    setLoggedInUser(nullSafeUser);

    if (nullSafeUser.error) {
      await logout({ returnTo: `${window.location.origin}/logout` });
    }
  }

  async function logOutUser() {
    const emptyUser: UserModel = { id: '', email: '', error: false, permissions: [] };
    await storeLoggedInUser(emptyUser);
    await logout({ returnTo: `${window.location.origin}/logout` });

    if (!isAuthenticated) {
      window.location.href = '/login';
      return;
    }
  }

  return (
    <LoggedInUserContext.Provider
      value={{
        setLoggedInUser: storeLoggedInUser,
        logOutUser,
        loginUrl: loginUrl,
        loggedInUser,
        error: loggedInUser?.error || false,
      }}
    >
      {children}
    </LoggedInUserContext.Provider>
  );
}

export default AuthProvider;
