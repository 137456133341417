import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInUserContext } from '../contexts/LoggedInUserContext';

function LogoutPage(): JSX.Element {
  const { logOutUser } = useContext(LoggedInUserContext);
  const { isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      logOutUser();
    }
  }, [isLoading]);

  return <></>;
}

export default LogoutPage;
