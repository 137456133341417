import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import constants from '../../../utils/constants';
import { ApiErrorResponse, ApiStatusCodes, PracticeConnectError } from './errors/practiceConnectError';
// TODO: load this from config; we might need a new config solution such as .env files, since the
// current solution can only be accessed from inside components

export class TokenFactory {
  private static instance: TokenFactory;

  private tokenMethod: () => Promise<string> = () => Promise.resolve('');

  public static getInstance(): TokenFactory {
    if (!this.instance) {
      this.instance = new TokenFactory();
    }
    return this.instance;
  }

  public setTokenMethod(fn: () => Promise<string>): void {
    this.tokenMethod = fn;
  }

  public async getBearerToken(): Promise<string> {
    return this.tokenMethod();
  }
}

export const practiceConnectApi = axios.create({
  baseURL: constants.PROJECT_CONNECT_API_BASE_URL,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' },
});

practiceConnectApi.interceptors.request.use((config) => {
  return addAuthHeader(config);
});

practiceConnectApi.interceptors.response.use(
  (response) => response,
  (error: AxiosError<ApiErrorResponse>) => {
    if (error.response?.status === ApiStatusCodes.FORBIDDEN) {
      // TODO: can we retry the request one-time with a new token?
    }

    const logData = {
      url: error.config?.url,
      status: error.response?.status,
      method: error.config?.method,
      responseData: error.response?.data,
      message: error.message,
      traceId: error.response?.headers['x-amzn-trace-id'],
    };

    const errorTitle = `API Error: ${logData.method?.toUpperCase()} ${logData.url}`;

    console.error(errorTitle, logData);

    throw new PracticeConnectError(error.response?.data?.message, error.response?.status, error.response?.data);
  },
);

const addAuthHeader = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const tokenFactory = TokenFactory.getInstance();
  const idToken = await tokenFactory.getBearerToken();

  config.headers.common['Authorization'] = `Bearer ${idToken}`;
  return config;
};
