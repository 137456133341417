import React, { useEffect, useState, useRef, useCallback, RefObject } from 'react';
import { createSpotClasses, Spot } from '../';
import { SpotPopoverContext } from './SpotPopoverContext';
import Close from './Close/Close';

require('./SpotPopover.scss');

type Rect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
};

/**
 * Adjust the height of the viewport if we have a parent bounding box
 * @param boundingRect - Bounding box of the viewport as returned form getBoundingClientRect()
 * @param windowHeight - The current viewport height
 * @returns {*} - The new height of the popover
 */
SpotPopover.getAdjustedHeight = (boundingRect: Rect, windowHeight: number) => {
  if (!boundingRect) {
    return windowHeight;
  }

  const { bottom, top } = boundingRect;

  return bottom - top > windowHeight - top ? windowHeight - top : bottom - top;
};

/**
 * Adjust the width of the viewport if we have a parent bounding box.
 * @param boundingRect - Bounding box of the parent as returned from getBoundingClientRect()
 * @param windowWidth - The current width of the viewport
 * @returns {*} - The adjusted width of the popover
 */
SpotPopover.getAdjustedWidth = (boundingRect: Rect, windowWidth: number) => {
  if (!boundingRect) {
    return windowWidth;
  }

  const { right, left } = boundingRect;
  return right - left > windowWidth - left ? windowWidth - left : right - left;
};

/**
 * Adjust the popover bounding box so that it matches adjustments to the parent bounding box if supplied
 * @param popoverRect - Bounding rectangle of the popover
 * @param boundingRect - Bounding rectangle of the parent box. Can be null in which case the popover rect is just returned.
 * @returns {{top: number, left: number, bottom: number, width: *, x: *, y: *, right: number, height: *}|*}
 */
SpotPopover.getAdjustedRect = (popoverRect: Rect, boundingRect: Rect): Rect => {
  if (!boundingRect) {
    return popoverRect;
  }

  return {
    bottom: popoverRect.bottom - boundingRect.top,
    height: popoverRect.height,
    left: popoverRect.left - boundingRect.left,
    right: popoverRect.right - boundingRect.left,
    top: popoverRect.top - boundingRect.top,
    width: popoverRect.width,
    x: popoverRect.x,
    y: popoverRect.y,
  };
};

/**
 * Adjust height of popover if needed to fit on the screen.
 * @param inRect - Bounding rect of the popover as returned from getBoundingClientRect()
 * @param boundingRect - Bounding rect of the parent window as returned from getBoundingClientRect(). If null the viewport is used.
 * @param wrapperRect - Bounding rect of trigger component before popover shown.
 * @param currentWindowHeight - The current height of the viewport
 * @param buffer - How much of a buffer to have at the bottom or top of popover when resizing
 * @returns {number} - The height of the popover for the current parameters
 */
SpotPopover.getMaxPopoverHeightWithinBounds = (
  inRect: Rect,
  wrapperRect: Rect,
  boundingRect: Rect,
  currentWindowHeight: number,
  buffer = 1,
): number => {
  const windowHeight = SpotPopover.getAdjustedHeight(boundingRect, currentWindowHeight);
  const rect = SpotPopover.getAdjustedRect(inRect, boundingRect);
  // Height of caret on popover.
  const caretHeight = wrapperRect.height + 16 * 2;

  // Space available below popover to display
  const bottomHeight = rect.top + rect.height + caretHeight > windowHeight ? Math.abs(rect.top - windowHeight) - caretHeight : rect.height;
  //Space available above popover to display. Calculations when drawing on top are based on the popover displaying from the bottom.
  // We offset this by subtracting the height tof the trigger and caret height * 2.
  const topHeight =
    rect.top - rect.height - caretHeight > 0
      ? rect.height
      : windowHeight - Math.abs(rect.top - windowHeight) - caretHeight - wrapperRect.height;
  //The greater of the two is where we want to set the height.
  return buffer * (bottomHeight >= topHeight ? bottomHeight : topHeight);
};

/**
 * Test to see if the popover fits on the currently supplied viewport or parent bounding box.
 * @param inRect - Bounding box of the popover as returned from getBoundingClientRect()
 * @param boundingRect - Bounding box of the parent as returned from getBoundingClientRect()
 * @param currentWindowWidth - current width of the viewport
 * @param currentWindowHeight - current height of the viewport
 * @param percentVisible - The minimum amount as a % that each side must be visible. Default to 100.
 * @returns {boolean} - If the popover is at least the supplied percentage visible on each side.
 */

SpotPopover.isElementInViewport = (
  inRect: Rect,
  boundingRect: Rect,
  currentWindowWidth: number,
  currentWindowHeight: number,
  percentVisible = 100,
) => {
  const windowHeight = SpotPopover.getAdjustedHeight(boundingRect, currentWindowHeight);
  const windowWidth = SpotPopover.getAdjustedWidth(boundingRect, currentWindowWidth);
  const rect = SpotPopover.getAdjustedRect(inRect, boundingRect);

  const { top, left, bottom, right, width, height } = rect;

  // Will popover fit in the screen height
  const fitHeight = 1 - Math.min(0, top / height) < percentVisible / 100;
  // Will popover fit in screen width;
  const fitWidth = Math.floor(100 - ((left >= 0 ? 0 : left) / +-(width / 1)) * 100) < percentVisible;
  // Is on screen vertically
  const onScreenVertical = Math.floor(100 - ((bottom - windowHeight) / height) * 100) < percentVisible;
  // Is on screen horizontal
  const onScreenHorizontal = Math.floor(100 - ((right - windowWidth) / width) * 100) < percentVisible;

  return !(fitHeight || fitWidth || onScreenVertical || onScreenHorizontal);
};

SpotPopover.getDrawClasses = (defaultDrawLocation: string) => {
  const drawClasses = [
    'spot-popover--bottom-center',
    'spot-popover--top-center',
    'spot-popover--bottom-left',
    'spot-popover--bottom-right ',
    'spot-popover--bottom-far-left',
    'spot-popover--bottom-far-right',
    'spot-popover--top-left',
    'spot-popover--top-far-left',
    'spot-popover--top-right',
    'spot-popover--top-far-right',
  ];

  if (defaultDrawLocation === 'top') {
    const temp = drawClasses[0];
    drawClasses[0] = drawClasses[1];
    drawClasses[1] = temp;
  } else if (defaultDrawLocation === 'left') {
    const temp = drawClasses[0];
    drawClasses[0] = drawClasses[2];
    drawClasses.splice(2, 1, temp);
  } else if (defaultDrawLocation === 'right') {
    const temp = drawClasses[0];
    drawClasses[0] = drawClasses[3];
    drawClasses.splice(3, 1, temp);
  }

  return drawClasses;
};

// Only three widths available for this component.
SpotPopover.widths = { small: '130px', medium: '175px', large: '240px', xlarge: '360px', xxlarge: '460px' };

interface Props {
  TriggerComponent?: any;
  children?: any;
  disabled?: any;
  parentRef?: RefObject<HTMLHeadingElement>;
  // Additional classes to pass in for styling the popover.
  popoverClassName?: string;
  // Additional style for the popover
  popoverWrapperClassName?: string;
  popoverWrapperStyles?: Record<string, unknown>;
  width: string;
  defaultDrawLocation?: string;
  isFullScreen?: boolean;
}

SpotPopover.defaultProps = {
  popoverClassName: '',
  // //Set default height and enable scrolling. This can be overridden by passing in custom styles.
  popoverWrapperClassName: '',
  popoverWrapperStyles: {},
  width: 'medium',
};

//TestIds are useful for automated tests. We declare them as static values for ease of reference.
SpotPopover.testIds = {
  popoverContainer: 'popover-container',
  popoverStyleContainer: 'popover-style',
  popoverMainComponent: 'popover-main',
};

/**
 * Render the popover.
 * @param children - The elements for the popover
 * @param TriggerComponent - The component to trigger the popover when clicked
 * @param parentRef - A reference to the parent that the popover will be located in. Used for the bounding box, can be null if viewport is used
 * @param popoverClassName - custom classes to pass to the popover
 * @param popoverWrapperClassName - custom classes to pass to the popoverComponent Wrapper
 * @param popoverWrapperStyles - Style to pass to the popover component classes
 * @param width - The width of the popover (small, medium, large, xlarge)
 * @param disabled
 * @returns {*}
 * @constructor
 */
function SpotPopover({
  children,
  TriggerComponent,
  parentRef,
  popoverClassName,
  popoverWrapperClassName,
  popoverWrapperStyles,
  width = 'medium',
  disabled,
  defaultDrawLocation = 'bottom',
  isFullScreen = false,
}: Props) {
  const defaultPopoverClasses = `spot-popover-hide ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.bottomCenter])}`;
  const [popoverClasses, setPopoverClasses] = useState(defaultPopoverClasses);
  const [popoverStyles, setPopoverStyles] = useState<any>({ overflow: 'auto', maxHeight: '100%' });
  const boundingRect = useRef(null);
  const popoverWrapperRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLInputElement>(null);

  const popoverEdgeDetectionClasses = SpotPopover.getDrawClasses(defaultDrawLocation);

  const hidePopover = useCallback(() => {
    setPopoverClasses(`spot-popover-hide ${popoverClasses}`);
  }, [popoverClasses]);

  // Find the class that best fits the screen. If there is no class that fits the screen do not show popover.
  // We cannot break this into a testable function because we need to try each class on the screen when it is
  // Invisible to see if it will show on the screen. We cannot use hooks outside of the main function. In this case
  // we are using state to set the class on the popover.
  const findVisibleClass = useCallback(() => {
    if (isFullScreen) {
      return `spot-popover--full-screen ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`;
    }
    const height = window.innerHeight || document.documentElement.clientHeight;
    const width = window.innerWidth || document.documentElement.clientWidth;

    // Loop through the classes, if we find one that fits the current screen, return it.
    for (const edgeClass of popoverEdgeDetectionClasses) {
      setPopoverClasses(`spot-popover-hide ${edgeClass} ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`);
      if (SpotPopover.isElementInViewport(popoverRef.current!.getBoundingClientRect(), boundingRect.current!, width, height)) {
        return `${edgeClass} ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`;
      }
    }
    return `spot-popover-hide ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`;
  }, [boundingRect, popoverRef, popoverEdgeDetectionClasses, isFullScreen]);

  const handleClick = useCallback(
    (e) => {
      if (!popoverWrapperRef?.current?.contains(e.target)) {
        hidePopover();
      } else if (!popoverRef.current!.contains(e.target) && !disabled) {
        // Hide the popover and reset to default before checking on the location
        if (isPopoverVisible()) {
          hidePopover();
        } else {
          setPopoverClasses(`spot-popover-hide ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`);
          //setPopoverStyles({ overflow: 'auto', maxHeight: popoverStyles.maxHeight });

          const height = window.innerHeight || document.documentElement.clientHeight;

          // Adjust the height of the popover if needed to fit the screen without going over.
          const newHeight = SpotPopover.getMaxPopoverHeightWithinBounds(
            popoverRef.current!.getBoundingClientRect(),
            popoverWrapperRef.current!.getBoundingClientRect(),
            boundingRect.current!,
            height,
            1,
          );

          // Set the new height
          setPopoverStyles(!isFullScreen ? { overflow: 'auto', maxHeight: newHeight } : { overflow: 'none', maxHeight: '100%' });
          // Find the class that fits the screen best and set it on the popover.
          setPopoverClasses(findVisibleClass());
        }
      }
      return `spot-popover-hide ${createSpotClasses(Spot.block.popover, undefined, [Spot.modifier.shown])}`;
    },
    [boundingRect, popoverRef, popoverWrapperRef, disabled, findVisibleClass, hidePopover, isFullScreen],
  );

  const handleResize = useCallback(() => {
    // @ts-ignore
    boundingRect.current = parentRef && parentRef.current ? parentRef.current.getBoundingClientRect() : null;
  }, [parentRef]);

  useEffect(() => {
    // @ts-ignore
    boundingRect.current = parentRef && parentRef.current && parentRef.current.getBoundingClientRect();

    document.addEventListener('click', handleClick);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('click', handleClick);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleClick, handleResize, parentRef]);

  // This is not the best way of testing if the popover is visible, but seems to do a good job of it.
  // If width or height are zero, we know it is not visible.
  const isPopoverVisible = () => {
    return !popoverRef?.current?.classList?.contains('spot-popover-hide');
  };

  const handleResizePopover = () => {
    if (isPopoverVisible() && isFullScreen) {
      hidePopover();
    }
  };

  window.addEventListener('resize', handleResizePopover);

  return (
    <span
      className={`spot-popover-wrapper ${popoverWrapperClassName}`}
      style={popoverWrapperStyles}
      data-testid={SpotPopover.testIds.popoverMainComponent}
      ref={popoverWrapperRef}
    >
      {TriggerComponent}
      <div
        className={`${popoverClasses} ${popoverClassName}`}
        data-testid={SpotPopover.testIds.popoverContainer}
        ref={popoverRef}
        style={!isFullScreen ? { width: SpotPopover.widths[width as keyof typeof SpotPopover.widths], borderRadius: 0 } : {}}
      >
        {isFullScreen && (
          <div className={'popover-close-box'} onClick={() => hidePopover()}>
            <Close />
          </div>
        )}

        <div style={popoverStyles} data-testid={SpotPopover.testIds.popoverStyleContainer}>
          <SpotPopoverContext.Provider value={{ hidePopover, popoverVisible: isPopoverVisible }}>
            {isFullScreen && <div className={'full-screen-children'}>{children}</div>}
            {!isFullScreen && children}
          </SpotPopoverContext.Provider>
        </div>
      </div>
    </span>
  );
}

export default SpotPopover;
