import React from 'react';
import AlertNotification from '../../images/AlertNotification/AlertNotification';
import './InlineMessage.scss';

export interface InlineMessageProps {
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  priority: 'standard' | 'medium' | 'high';
  level: 'default' | 'success' | 'warning' | 'danger';
}

export default function InlineMessage({ title, message, priority, level }: InlineMessageProps): JSX.Element {
  const baseClass = `spot-message priority-${priority}--${level}`;
  return (
    <div className={baseClass}>
      <div className="message--content">
        {title && <div className="message--header">{title}</div>}
        {message && <div className="message--body">{message}</div>}
      </div>
      <div className="icon-tab">
        <AlertNotification />
      </div>
    </div>
  );
}
