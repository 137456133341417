import React from 'react';
import translate from 'counterpart';
import { SpotIcon, Spot } from '../../../../../Spot';
import EnrollmentRightPaneFooter from '../../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
import './EnrollmentSearchingForPractice.scss';

function EnrollmentSearchingForPractice(): JSX.Element {
  return (
    <div className={'enrollment-searching-for-practice-container'}>
      <div className={'enrollment-searching-for-practice-text'}>
        <div className={'searching-content-title'} data-automation-id={'enrollmentSearchingPracticeText'}>
          {translate('searchingFindYourPractices')}
        </div>
        <div className={'searching-spinner'}>
          <SpotIcon
            title={'spinner'}
            iconName={Spot.icons.spinner}
            data-automation-id={'findingPracticeLoadingSpinner'}
            className={'spot-loading-spinner spot-loading-spinner--large'}
          />
        </div>
      </div>
      <div className={'enrollment-find-practice-footer'}>
        <EnrollmentRightPaneFooter hidePrimary={true} />
      </div>
    </div>
  );
}

export default EnrollmentSearchingForPractice;
