import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfigContext } from '../contexts/ConfigContext';
import TagManager from 'react-gtm-module';

GoogleTagManager.propTypes = {
  children: PropTypes.node,
};

export default function GoogleTagManager({ children }: { children: JSX.Element }): JSX.Element {
  const { environment, gtmId, gtmAuth, gtmPreview } = useContext(ConfigContext);
  if (environment !== 'local') {
    TagManager.initialize({
      gtmId,
      dataLayer: {
        environment,
      },
      auth: gtmAuth || undefined,
      preview: gtmPreview || undefined,
    });
  }

  return <>{children}</>;
}
