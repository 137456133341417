import React from 'react';
import PropTypes from 'prop-types';
import { Spot, createSpotClasses } from '../';

SpotIcon.propTypes = {
    iconName: PropTypes.oneOf([...Object.values(Spot.icons), undefined]),
    className: PropTypes.string,
    title: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    style: PropTypes.object
};

const spotIconClass = createSpotClasses(Spot.block.icon);

export default React.memo(SpotIcon);

/**
 *
 * @param iconName
 * @param title
 * @param className
 * @param otherProps
 * @returns {null|*}
 * @constructor
 */ function SpotIcon({ iconName, title, className, ...otherProps }) {
    if (!iconName) {
        //It is convenient to toggle visibility through lack of an iconName rather than have a separate property.
        return null;
    }
    return (
        <svg className={`${spotIconClass} ${className}`} {...otherProps} aria-labelledby="title">
            <title>{title}</title>
            <use href={'/spot_icons.svg#' + iconName} />
            {/* TODO: Remove the <use> tag with the xlinkHref attribute when Safari implements support for the href
                attribute in SVGs because it's deprecated in SVG 2.0. The <use> tag with the xlinkHref attribute was
                added in COM-450 because SVGs weren't rendering in Safari, and is essentially a duplicate of the above
                href attribute. It was decided to keep both attributes for the time being. */}
            <use xlinkHref={'/spot_icons.svg#' + iconName} />
        </svg>
    );
}
