import React from 'react';
import Routes from './Routes/Routes';
import Config from './components/Config/Config';
import AuthProvider from './components/AuthProvider/AuthProvider';
import GoogleTagManager from './components/GoogleTagManager/GoogleTagManager';
import HubspotIdentity from './components/Hubspot/HubspotIdentity';

function App(): JSX.Element {
  return (
    <Config>
      <AuthProvider>
        <HubspotIdentity />
        <GoogleTagManager>
          <Routes />
        </GoogleTagManager>
      </AuthProvider>
    </Config>
  );
}

export default App;
