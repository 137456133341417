import { EulaModel } from './models/eulaModel';
import { QuestionsModel } from './models/questionsModel';
import { EnrollmentResponseData } from './models/enrollmentResponse';
import { EnrollmentRequest } from './models/enrollmentRequestData';
import { practiceConnectApi } from '../practiceConnectApi';
import constants from '../../../../utils/constants';

import idexxEulaContent from './idexxEula.json';
import enterpriseEulaContent from './enterpriseEula.json';

export const getIdexxEula = (): Promise<EulaModel> => {
  const eula: EulaModel = idexxEulaContent;
  return Promise.resolve(eula);
};

export const getQuestions = (): Promise<QuestionsModel[]> => {
  const questions: QuestionsModel[] = idexxEulaContent.questions;
  return Promise.resolve(questions);
};

export const getEnterpriseEula = (): Promise<EulaModel> => {
  const eula: EulaModel = enterpriseEulaContent;
  return Promise.resolve(eula);
};

export const submitEula = (request: EnrollmentRequest): Promise<EnrollmentResponseData> => {
  return practiceConnectApi.post(`${constants.PROJECT_CONNECT_API_BASE_URL}/v1/enrollment`, request);
};
