import React from 'react';
import './EnrollmentRightPaneFooter.scss';

interface IProps {
  hidePrimary?: boolean;
  submitFunction?: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  secondaryButtonFunction?: () => void;
}

function EnrollmentRightPaneFooter({
  submitFunction,
  buttonText,
  secondaryButtonText,
  secondaryButtonFunction,
  hidePrimary,
}: IProps): JSX.Element {
  return (
    <div className={'enrollment-right-pane-footer-container'}>
      <div className={'enrollment-footer-button-container'}>
        {secondaryButtonText && (
          <div
            className={'enrollment-footer-secondary-button'}
            onClick={secondaryButtonFunction}
            data-automation-id={'enrollmentFooterSecondaryButton'}
          >
            {secondaryButtonText}
          </div>
        )}
        {buttonText && !hidePrimary && (
          <button className={'enrollment-footer-button'} onClick={submitFunction} data-automation-id={'enrollmentFooterButton'}>
            {buttonText}
          </button>
        )}
        {hidePrimary && (
          <button
            className={'enrollment-footer-button-hidden'}
            onClick={submitFunction}
            data-automation-id={'enrollmentFooterButtonHidden'}
          >
            none
          </button>
        )}
      </div>
    </div>
  );
}

export default EnrollmentRightPaneFooter;
