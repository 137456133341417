import React, { useContext, useEffect, useRef, useState } from 'react';
import translate from 'counterpart';
import EnrollmentEulaFooter from './EnrollmentEulaFooter/EnrollmentEulaFooter';
import { getEnterpriseEula, getIdexxEula, submitEula } from '../../../../../services/http/practiceConnect/eula/eulaService';
import CONSTANTS from '../../../../../utils/constants';
import EnrollmentTechnicalContact from '../EnrollmentTechnicalContact/EnrollmentTechnicalContact';
import {
  EnrollmentRequest,
  EnrollmentRequestData,
  Question,
} from '../../../../../services/http/practiceConnect/eula/models/enrollmentRequestData';
import EnrollmentEulaSubmitPage from './EnrollmentEulaSubmitPage/EnrollmentEulaSubmitPage';
import { EnrollmentDataContext } from '../../../../contexts/EnrollmentDataContext';
import { EulaModel } from '../../../../../services/http/practiceConnect/eula/models/eulaModel';
import { cleanPhoneNumber } from '../../../../../utils/format';
import EnrollmentCallUs from '../../EnrollmentCallUs/EnrollmentCallUs';
import { validateEmail, validatePhone } from '../../../../../utils/validation';
import { Form, SpotCheckBox, ValidatedFormInput } from '../../../../../Spot/Form';
import SpotModal from '../../../../../Spot/Form/SpotModal';
import { onboardUser } from '../../../../../services/http/practiceConnect/user/userService';
import { LoggedInUserContext } from '../../../../contexts/LoggedInUserContext';
import { OnboardingPracticeModel } from '../../../../../services/http/practiceConnect/practice/onboardingPracticeModel';
import { getCurrentApplication } from '../../../../../utils/application';
import { useHistory } from 'react-router-dom';

require('./EnrollmentEula.scss');
interface IProps {
  practices: any;
}
function EnrollmentEula({ practices }: IProps) {
  const scrollRef = useRef<HTMLHeadingElement>(null);
  const [previous, setPrevious] = useState(false);
  const [eulaContent, setEulaContent] = useState<EulaModel>({
    termsAndConditions: {
      type: '',
      name: '',
      version: '',
      content: '',
    },
    pimsEnum: [],
    questions: [],
  });
  const [enterpriseEulaContent, setEnterpriseEulaContent] = useState<EulaModel>({
    termsAndConditions: {
      type: '',
      name: '',
      version: '',
      content: '',
    },
    pimsEnum: [],
    questions: [],
  });
  const [showLoading, setShowLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationError, setConfirmationError] = useState(false);
  const { loggedInUser } = useContext(LoggedInUserContext);
  const [eulaContactLastNameError, setEulaContactLastNameError] = useState('');
  const [eulaContactFirstNameError, setEulaContactFirstNameError] = useState('');
  const [eulaContactPositionError, setEulaContactPositionError] = useState('');
  const [eulaContactPhoneNumberError, setEulaContactPhoneNumberError] = useState('');
  const [eulaContactEmailError, setEulaContactEmailError] = useState('');
  const [eulaContactBusinessDecisionsError, setEulaContactBusinessDecisionsError] = useState('');
  const [eulaContactReadEulaError, setEulaContactReadEulaError] = useState('');
  const [validationOn, setValidationOn] = useState(true);
  const [formValid, setFormValid] = useState(false);
  const [showQuestion1Modal, setShowQuestion1Modal] = useState(false);
  const [showQuestion2Modal, setShowQuestion2Modal] = useState(false);
  const history = useHistory();

  const {
    eulaContactFirstName,
    eulaContactLastName,
    eulaContactPosition,
    eulaContactPhoneNumber,
    eulaContactEmail,
    contactEmail,
    contactPhone,
    contactPosition,
    contactFirstName,
    contactLastName,
    selectedPractice,
    sapId,
    pims,
    setEulaContactFirstName,
    setEulaContactLastName,
    setEulaContactPosition,
    setEulaContactPhoneNumber,
    setEulaContactEmail,
    setEulaContactBusinessDecisions,
    eulaContactBusinessDecisions,
    setEulaContactReadEula,
    eulaContactReadEula,
  } = useContext(EnrollmentDataContext);

  useEffect(() => {
    if (eulaContactEmailError) {
      setEulaContactEmailError(validateEmail(eulaContactEmail));
    }
    if (eulaContactPhoneNumberError) {
      setEulaContactPhoneNumberError(validatePhone(eulaContactPhoneNumber));
    }
    if (eulaContactFirstNameError && eulaContactFirstName) {
      setEulaContactFirstNameError('');
    }
    if (eulaContactLastNameError && eulaContactLastName) {
      setEulaContactLastNameError('');
    }
    if (eulaContactPositionError && eulaContactPosition) {
      setEulaContactPositionError('');
    }
    if (eulaContactBusinessDecisions) {
      setEulaContactBusinessDecisionsError('');
    }
    if (eulaContactReadEula) {
      setEulaContactReadEulaError('');
    }
  }, [
    eulaContactFirstName,
    eulaContactLastName,
    eulaContactPhoneNumber,
    eulaContactEmail,
    eulaContactPosition,
    eulaContactReadEula,
    eulaContactBusinessDecisions,
  ]);

  useEffect(() => {
    setFormValid(
      !!(
        eulaContactFirstName &&
        !eulaContactFirstNameError &&
        eulaContactLastName &&
        !eulaContactLastNameError &&
        eulaContactPhoneNumber &&
        !eulaContactPhoneNumberError &&
        eulaContactEmail &&
        !eulaContactEmailError &&
        eulaContactPosition &&
        !eulaContactPositionError &&
        eulaContactReadEula &&
        !eulaContactReadEulaError &&
        eulaContactBusinessDecisions &&
        !eulaContactBusinessDecisionsError
      ),
    );
  }, [
    eulaContactFirstName,
    eulaContactLastName,
    eulaContactPhoneNumber,
    eulaContactEmail,
    eulaContactPosition,
    eulaContactBusinessDecisions,
    eulaContactReadEula,
    eulaContactFirstNameError,
    eulaContactLastNameError,
    eulaContactPhoneNumberError,
    eulaContactEmailError,
    eulaContactPositionError,
    eulaContactBusinessDecisionsError,
    eulaContactReadEulaError,
  ]);

  useEffect(() => {
    if (eulaContactEmail) {
      setEulaContactEmailError(validateEmail(eulaContactEmail));
    }
    if (eulaContactPhoneNumber) {
      setEulaContactPhoneNumberError(validatePhone(eulaContactPhoneNumber));
    }
    setFormValid(
      !!(
        eulaContactFirstName &&
        !eulaContactFirstNameError &&
        eulaContactLastName &&
        !eulaContactLastNameError &&
        eulaContactPhoneNumber &&
        !eulaContactPhoneNumberError &&
        eulaContactEmail &&
        !eulaContactEmailError &&
        eulaContactPosition &&
        !eulaContactPositionError &&
        eulaContactReadEula &&
        !eulaContactReadEulaError &&
        eulaContactBusinessDecisions &&
        !eulaContactBusinessDecisionsError
      ),
    );
  }, []);

  const handleSubmit = () => {
    if (formValid) {
      submitFunction();
    } else {
      setValidationOn(true);

      if (!eulaContactEmail) {
        setEulaContactEmailError(translate('requiredField'));
      } else {
        setEulaContactEmailError(validateEmail(eulaContactEmail));
      }
      if (!eulaContactPhoneNumber) {
        setEulaContactPhoneNumberError(translate('requiredField'));
      } else {
        setEulaContactPhoneNumberError(validatePhone(eulaContactPhoneNumber));
      }
      if (!eulaContactFirstName) {
        setEulaContactFirstNameError(translate('requiredField'));
      }
      if (!eulaContactLastName) {
        setEulaContactLastNameError(translate('requiredField'));
      }
      if (!eulaContactPosition) {
        setEulaContactPositionError(translate('requiredField'));
      }
      if (!eulaContactBusinessDecisions) {
        setEulaContactBusinessDecisionsError(translate('requiredField'));
      }
      if (!eulaContactReadEula) {
        setEulaContactReadEulaError(translate('requiredField'));
      }
    }
  };

  const update = () => {
    if (scrollRef?.current && window.innerWidth < CONSTANTS.MINIMUM_SCREEN_SIZE_RESPONSIVE) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  window.addEventListener('resize', update);

  useEffect(() => {
    getIdexxEula().then((data) => {
      setEulaContent(data);
    });

    getEnterpriseEula().then((data) => {
      setEnterpriseEulaContent(data);
    });
  }, []);

  if (previous) {
    return <EnrollmentTechnicalContact practices={practices} />;
  }

  if (showLoading) {
    return <EnrollmentEulaSubmitPage />;
  }

  if (showConfirmation) {
    if (!confirmationError) {
      history.push('/confirmation');
      return <></>;
    } else {
      return <EnrollmentCallUs previousPage={<EnrollmentEula practices={practices} />} />;
    }
  }

  const submitFunction = async () => {
    setShowLoading(true);
    try {
      const application = getCurrentApplication();

      await Promise.all([submitEula(buildEulaPayload()), onboardUser(loggedInUser!.email, application, buildOnboardingPracticesPayload())]);
    } catch (error) {
      console.error(error);
      setConfirmationError(true);
    }
    setShowLoading(false);
    setShowConfirmation(true);
  };

  const buildEulaPayload = (): EnrollmentRequest => {
    const data: EnrollmentRequestData = {};
    data.Contacts = [];
    data.PracticeName = selectedPractice.name;
    data.Address = selectedPractice.address1;
    data.Address2 = selectedPractice.address2;
    data.City = selectedPractice.city;
    data.State = selectedPractice.region;
    data.Country = selectedPractice.country;
    data.Phone = cleanPhoneNumber(eulaContactPhoneNumber.trim());
    data.Zip = selectedPractice.zip;
    data.SapID = sapId;
    data.Contacts = [
      {
        Type: 'PracticeContact',
        FirstName: contactFirstName.trim(),
        LastName: contactLastName.trim(),
        Email: contactEmail.trim(),
        Phone: cleanPhoneNumber(contactPhone.trim()),
        JobTitle: contactPosition.trim(),
      },
      {
        Type: 'TechnicalContact',
        FirstName: contactFirstName.trim(),
        LastName: contactLastName.trim(),
        Email: contactEmail.trim(),
        Phone: cleanPhoneNumber(contactPhone.trim()),
        JobTitle: contactPosition.trim(),
      },
      {
        Type: 'EulaContact',
        FirstName: eulaContactFirstName.trim(),
        LastName: eulaContactLastName.trim(),
        Email: eulaContactEmail.trim(),
        Phone: cleanPhoneNumber(eulaContactPhoneNumber.trim()),
        JobTitle: eulaContactPosition.trim(),
      },
    ];
    data.SignedEULA = {
      Type: eulaContent.termsAndConditions.type,
      Name: eulaContent.termsAndConditions.name,
      Version: eulaContent.termsAndConditions.version,
      SignedBy: data.Contacts[2],
    };
    data.PIMS = pims;
    data.Questions = [];
    for (const question of eulaContent.questions) {
      const answer: Question = {
        QuestionText: question.questionText,
        QuestionAnswer: 'no',
      };
      data.Questions.push(answer);
    }

    const application = getCurrentApplication();

    return {
      application,
      pims,
      data,
    };
  };

  const buildOnboardingPracticesPayload = (): OnboardingPracticeModel[] => {
    return [
      {
        practiceName: selectedPractice.name,
        sapId,
        zipCode: selectedPractice.zip || 'unknown',
        address: selectedPractice.address1,
        address2: selectedPractice.address2,
        city: selectedPractice.city,
        state: selectedPractice.region,
        pimsName: pims,
      },
    ];
  };

  const showIdexxEula = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setShowQuestion1Modal(true);
  };
  const showEnterpriseEula = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setShowQuestion2Modal(true);
  };

  const closeAllEulas = () => {
    setShowQuestion1Modal(false);
    setShowQuestion2Modal(false);
  };

  const rawLinkMatcher = /(.*)<link>(.*)<\/link>(.*)/;
  const questionOneRawText = translate('eulaQuestion1WithLink').match(rawLinkMatcher) || [];
  const questionOneText = (
    <>
      {questionOneRawText[1]}
      <a onClick={showIdexxEula} className={'spot-link'} data-automation-id={'eula-link-1'}>
        {questionOneRawText[2]}
      </a>
      {questionOneRawText[3]}
    </>
  );
  const questionTwoRawText = translate('eulaQuestion2WithLink').match(rawLinkMatcher) || [];
  const questionTwoText = (
    <>
      {questionTwoRawText[1]}
      <a onClick={showEnterpriseEula} className={'spot-link'} data-automation-id={'eula-link-2'}>
        {questionTwoRawText[2]}
      </a>
      {questionTwoRawText[3]}
    </>
  );

  return (
    <div className={'enrollment-eula-parent-container'}>
      <div className={'enrollment-eula-container-scroll'} ref={scrollRef}>
        <div className={'enrollment-eula-container'}>
          <SpotModal
            id={'idexx-eula-modal'}
            extraClasses={'eula-modal'}
            title={translate('eulaIdexxTermsTitle')}
            footerPrimaryButtonText={translate('close')}
            visibility={showQuestion1Modal}
            onCloseButton={closeAllEulas}
            onPrimaryButton={closeAllEulas}
            content={<div className={'eula-text-content'}>{eulaContent.termsAndConditions.content}</div>}
          />
          <SpotModal
            id={'enterprise-eula-modal'}
            extraClasses={'eula-modal'}
            title={translate('eulaEnterpriseTermsTitle')}
            footerPrimaryButtonText={translate('close')}
            visibility={showQuestion2Modal}
            onCloseButton={closeAllEulas}
            onPrimaryButton={closeAllEulas}
            content={
              <div
                className={'eula-text-content'}
                dangerouslySetInnerHTML={{ __html: enterpriseEulaContent.termsAndConditions.content }}
              ></div>
            }
          />
          <div className={'content-input-form-main'}>
            <div className={'content-input-form-title'} data-automation-id={'enrollmentEulaTitleText'}>
              {translate('eulaUserHasAuthorization', { clinicName: selectedPractice.name })}
            </div>
            <div className={'content-input-form'}>
              <Form>
                <div className="spot-form__field-group spot-form__field-group--vertical">
                  <div className={'eula-name-title'}>
                    <div className={'eula-first-last-name'}>
                      <div className="spot-form__field-group eula-first-name" data-automation-id={'enrollmentEulaFirstName'}>
                        <ValidatedFormInput
                          {...{
                            onChange: setEulaContactFirstName,
                            label: 'eulaContactFirstName',
                            initialValue: eulaContactFirstName,
                            errorText: eulaContactFirstNameError,
                            showError: validationOn,
                            maxLength: '30',
                            id: 'enrollment-eula-contact-first-name-test-id',
                          }}
                        />
                      </div>

                      <div className="spot-form__field-group eula-last-name" data-automation-id={'enrollmentEulaLastName'}>
                        <ValidatedFormInput
                          {...{
                            onChange: setEulaContactLastName,
                            label: 'eulaContactLastName',
                            initialValue: eulaContactLastName,
                            errorText: eulaContactLastNameError,
                            showError: validationOn,
                            maxLength: '30',
                            id: 'enrollment-eula-contact-last-name-test-id',
                          }}
                        />
                      </div>
                    </div>

                    <div className="spot-form__field-group eula-position" data-automation-id={'enrollmentEulaPosition'}>
                      <ValidatedFormInput
                        {...{
                          onChange: setEulaContactPosition,
                          label: 'eulaContactPositionTitle',
                          initialValue: eulaContactPosition,
                          errorText: eulaContactPositionError,
                          showError: validationOn,
                          maxLength: '30',
                          id: 'enrollment-eula-contact-position-test-id',
                        }}
                      />
                    </div>
                  </div>
                  <div className={'eula-email-phone'}>
                    <div className="spot-form__field-group eula-phone" data-automation-id={'enrollmentEulaPhone'}>
                      <ValidatedFormInput
                        {...{
                          onChange: setEulaContactPhoneNumber,
                          label: 'eulaContactPhone',
                          initialValue: eulaContactPhoneNumber,
                          errorText: eulaContactPhoneNumberError,
                          showError: validationOn,
                          id: 'enrollment-eula-contact-phone-test-id',
                          type: 'mask',
                          mask: '_',
                          format: '(###) ###-####',
                          onBlur: () => {
                            setEulaContactPhoneNumberError(validatePhone(eulaContactPhoneNumber));
                          },
                        }}
                      />
                    </div>

                    <div className="spot-form__field-group eula-email" data-automation-id={'enrollmentEulaEmail'}>
                      <ValidatedFormInput
                        {...{
                          onChange: setEulaContactEmail,
                          label: 'eulaContactEmail',
                          initialValue: eulaContactEmail,
                          errorText: eulaContactEmailError,
                          showError: validationOn,
                          id: 'enrollment-eula-contact-email-test-id',
                          onBlur: () => {
                            setEulaContactEmailError(validateEmail(eulaContactEmail));
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>

              <div className={'checkboxes-container'}>
                <div data-automation-id={'enrollmentEulaQuestion1'}>
                  <SpotCheckBox
                    align={'left'}
                    label={questionOneText}
                    onChange={(e: any) => setEulaContactBusinessDecisions(e.target.checked)}
                    defaultChecked={eulaContactBusinessDecisions}
                    isSmall={false}
                    disabled={false}
                    errorText={eulaContactBusinessDecisionsError}
                    showError={validationOn}
                    id={'eulaCheckBoxQuestion1Id'}
                  />
                </div>
                <div data-automation-id={'enrollmentEulaQuestion2'}>
                  <SpotCheckBox
                    align={'left'}
                    label={questionTwoText}
                    onChange={(e: any) => setEulaContactReadEula(e.target.checked)}
                    defaultChecked={eulaContactReadEula}
                    isSmall={false}
                    disabled={false}
                    errorText={eulaContactReadEulaError}
                    showError={validationOn}
                    id={'eulaCheckBoxQuestion2Id'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'enrollment-eula-footer'}>
        <EnrollmentEulaFooter
          submitFunction={handleSubmit}
          buttonText={translate('authorizeAccess')}
          secondaryButtonText={translate('previous')}
          secondaryButtonFunction={() => setPrevious(true)}
        />
      </div>
    </div>
  );
}

export default EnrollmentEula;
