import React, { useContext, useEffect, useState } from 'react';
import translate from 'counterpart';
import EnrollmentRightPaneFooter from '../../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
import EnrollmentFindPractice from '../EnrollmentFindPractice';
import EnrollmentTechnicalContact from '../EnrollmentTechnicalContact/EnrollmentTechnicalContact';
import { PracticeModel } from '../../../../../services/http/practiceConnect/practice/practiceModel';
import { EnrollmentDataContext } from '../../../../contexts/EnrollmentDataContext';
import Dropdown from '../../../../../Spot/Form/Dropdown/Dropdown';
import EnrollmentCallUs from '../../EnrollmentCallUs/EnrollmentCallUs';
import './EnrollmentPracticeFound.scss';
import { PimsModel } from '../../../../../services/http/practiceConnect/pims/pimsModel';
import { DATAPOINT_SOURCE, getSupportedPims } from '../../../../../services/http/practiceConnect/pims/pimsService';
import { Spot, SpotIcon } from '../../../../../Spot';
import constants from '../../../../../utils/constants';
import { ConfigContext } from '../../../../contexts/ConfigContext';

interface IProps {
  practices: PracticeModel[];
}

function EnrollmentPracticeFound({ practices }: IProps): JSX.Element {
  const [previous, setPrevious] = useState(false);
  const [technicalContact, setTechnicalContact] = useState(false);
  const [pimsError, setPimsError] = useState(false);
  const [callUs, setCallUs] = useState(false);
  const [supportedPims, setSupportedPims] = useState<PimsModel[]>([]);
  const [datapointPims, setDatapointPims] = useState<PimsModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [practiceAlreadyExists, setPracticeAlreadyExists] = useState(false);
  const { setSelectedPractice, pims, setPims } = useContext(EnrollmentDataContext);
  const { setCanSkipEnrollment } = useContext(ConfigContext);

  useEffect(() => {
    let skipPimsRequest = false;
    practices.forEach((practice) => {
      if (practice.owner) {
        setPracticeAlreadyExists(true);
        setCanSkipEnrollment(false);
        skipPimsRequest = true;
      }
    });

    if (!skipPimsRequest) {
      getSupportedPims()
        .then((response) => {
          setSupportedPims(response.data);
          const dpPims = response.data
            .filter(({ source }) => source === DATAPOINT_SOURCE)
            .sort((pimsA, pimsB) => {
              if (pimsA.name.toLowerCase() < pimsB.name.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            });
          setDatapointPims(dpPims);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error('Failed to fetch supported pims', err);
          setCallUs(true);
        });
    }
  }, []);

  const resetData = () => {
    setPims('');
  };

  if (previous) {
    return <EnrollmentFindPractice />;
  }

  if (callUs) {
    return <EnrollmentCallUs previousPage={<EnrollmentFindPractice />} />;
  }

  if (technicalContact) {
    return <EnrollmentTechnicalContact practices={practices} />;
  }

  setSelectedPractice(practices[0]);

  const handlePimsChange = (selected: { label: string; value: string }) => {
    if (selected && !Array.isArray(selected)) {
      setPims(selected.value);
      setPimsError(false);
    }
  };

  const onSubmit = () => {
    if (pims === 'Other') {
      resetData();
      setCallUs(true);
    } else if (pims) {
      setTechnicalContact(true);
    } else {
      setPimsError(true);
    }
  };

  const onPreviousClick = () => {
    resetData();
    setPrevious(true);
  };

  const getSystemOwnerDisplayName = (practice: PracticeModel) => {
    if (!practice.owner) {
      return translate('notApplicable');
    }

    if (!practice.owner.firstName && !practice.owner.lastName) {
      return translate('unknown');
    }

    return `${practice.owner.firstName} ${practice.owner.lastName}`.trim();
  };

  return (
    <div className={'enrollment-practice-found-container'}>
      <div className={'enrollment-practice-found-container-scroll'}>
        <div className={'enrollment-practice-found-text'}>
          <div className={'searching-content-title'} data-automation-id={'enrollmentFoundPracticeText'}>
            {practiceAlreadyExists ? translate('practiceAlreadyConnected') : translate('weveFoundYourPractice')}
          </div>
          <div className={'content-image'}>
            <img className={'house-image'} src={'/house.png'} alt={translate('altText.house')} aria-hidden={true} />
          </div>
          {practices.map((practice: PracticeModel, index: number) => (
            <div key={index}>
              <div className={'found-practice-name'}>{practice.name}</div>
              <div>
                {practice.city}, {practice.region}
              </div>
              {practiceAlreadyExists && (
                <div className={'practice-owner'} data-automation-id={'practice-owner'}>
                  <div className={'practice-owner-name'} data-automation-id={'practice-owner-name'}>
                    {translate('systemOwner')} <b>{getSystemOwnerDisplayName(practice)}</b>
                  </div>
                  <div className={'practice-owner-instructions'}>{translate('systemOwnerAccess')}</div>
                </div>
              )}
            </div>
          ))}
          {!practiceAlreadyExists && (
            <div className={'enrollment-pims-container'} data-automation-id={'enrollment-pims-dropdown-container'}>
              {isLoading && (
                <div className={'loading-spinner'}>
                  <SpotIcon
                    title={'spinner'}
                    iconName={Spot.icons.spinner}
                    data-automation-id={'findingPracticeLoadingSpinner'}
                    className={'spot-loading-spinner spot-loading-spinner--large'}
                  />
                </div>
              )}
              {!isLoading && (
                <Dropdown
                  id={'enrollment-pims-dropdown'}
                  selectProps={{
                    placeholder: translate('pleaseSelect'),
                    // Supported pims: https://wiki.idexx.com/pages/viewpage.action?pageId=289093332
                    options: [
                      ...datapointPims.map(({ id, name }) => ({ label: name, value: id })),
                      { label: translate('other'), value: 'Other' },
                    ],
                    isMulti: false,
                  }}
                  label={'pims'}
                  onChange={handlePimsChange}
                  initialValue={pims}
                  showError={pimsError}
                  errorText={translate('missingPimsError')}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {!practiceAlreadyExists && (
        <div className={'enrollment-find-practice-footer'}>
          <EnrollmentRightPaneFooter
            submitFunction={onSubmit}
            buttonText={translate('thatsRight')}
            secondaryButtonText={translate('previous')}
            secondaryButtonFunction={onPreviousClick}
          />
        </div>
      )}
    </div>
  );
}

export default EnrollmentPracticeFound;
