import React, { useContext, useState } from 'react';
import translate from 'counterpart';
import EnrollmentRightPaneFooter from '../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
import constants from '../../../../utils/constants';
import { LoggedInUserContext } from '../../../contexts/LoggedInUserContext';
import { onboardUser } from '../../../../services/http/practiceConnect/user/userService';
import { Spot, SpotIcon } from '../../../../Spot';
require('./EnrollmentCallUs.scss');

interface IProps {
  previousPage: any;
  showBackButton?: boolean;
}

function EnrollmentCallUs({ previousPage, showBackButton = true }: IProps) {
  const [previous, setPrevious] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (previous) {
    return previousPage;
  }

  const onSkip = async () => {
    if (!isSkipping) {
      setIsSkipping(true);
      let application = localStorage.getItem(constants.LOCAL_STORAGE_APPLICATION_ID);
      if (!application || application === constants.UNKNOWN_APPLICATION) {
        application = 'enterprise';
      }
      try {
        await onboardUser(loggedInUser!.email, application, []);
        window.location.href = constants.ENTERPRISE_REDIRECT_URL;
      } catch (err) {
        setIsSkipping(false);
      }
    }
  };

  return (
    <div className={'enrollment-call-us-container'}>
      <div className={'enrollment-call-us-text'}>
        <div className={'call-us-title-container'}>
          <div className={'call-us-title'} data-automation-id={'havingTroubleText'}>
            {translate('wereHavingTrouble')}
          </div>
        </div>
        <div className={'content-image'}>
          <img className={'no-results-dog'} src={'/noResultsDog.png'} alt={'dogImage'} />
        </div>
        <div className={'call-us-give-us-a-call-container'}>
          <div className={'call-us-give-us-a-call-text'} data-automation-id={'callUsText'}>
            {translate('giveUsACall')}
          </div>
          <div className={'call-us-phone-number'}>
            <a href={constants.SUPPORT_PHONE_HREF}>{constants.SUPPORT_PHONE_TEXT}</a>
          </div>
          <div className={'call-us-skip'}>
            {!isSkipping && <a onClick={onSkip}>{translate('skipForNow')}</a>}
            {isSkipping && (
              <SpotIcon
                title={'spinner'}
                iconName={Spot.icons.spinner}
                data-automation-id={'findingPracticeLoadingSpinner'}
                className={'spot-loading-spinner spot-loading-spinner--large'}
              />
            )}
          </div>
        </div>
      </div>
      <div className={'enrollment-call-us-footer'}>
        <EnrollmentRightPaneFooter
          hidePrimary={true}
          secondaryButtonText={showBackButton ? translate('previous') : undefined}
          secondaryButtonFunction={() => setPrevious(true)}
        />
      </div>
    </div>
  );
}

export default EnrollmentCallUs;
