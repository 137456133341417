import React from 'react';
import PropTypes from 'prop-types';
import SpotIcon from '../Icon/SpotIcon';
import { createSpotClasses, Spot } from '../Spot.js';
require('./SpotCheckBox.scss');
SpotCheckBox.propTypes = {
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  label: PropTypes.string,
  showError: PropTypes.bool,
  errorText: PropTypes.string,
};

export default function SpotCheckBox({ id, isSmall, label, defaultChecked, disabled, errorText, showError, ...props }) {
  return (
    <label className={createSpotClasses(Spot.block.form, Spot.element.checkbox, [isSmall && Spot.modifier.small])}>
      <input
        id={id}
        className="spot-form__checkbox-input"
        type={'checkbox'}
        defaultChecked={defaultChecked}
        disabled={disabled}
        {...props}
      />
      <span className={showError && errorText ? 'spot-form__checkbox-inner checkbox-error-border' : 'spot-form__checkbox-inner'}>
        <span className="spot-form__checkbox-visual">
          <SpotIcon className="spot-form__checkbox-checked-icon" title="checkmark" iconName="checkmark" />
          <SpotIcon className="spot-form__checkbox-indeterminate-icon" title="checkbox-indeterminate" iconName="checkbox-indeterminate" />
        </span>
        {label && <span className="spot-form__checkbox-label">{label}</span>}
      </span>
      {showError && errorText && (
        <span className={createSpotClasses(Spot.block.form, Spot.element.fieldError)} role="alert">
          <span className={createSpotClasses(Spot.block.form, Spot.element.fieldErrorText)}>{errorText}</span>
        </span>
      )}
    </label>
  );
}
