import React from 'react';
import CancelIcon from '../../images/Cancel/Cancel';
require('./SpotModal.scss');

interface SpotModalProps {
  id: string;
  title: string;
  footerCancelButtonText?: string;
  footerPrimaryButtonText?: string;
  onCloseButton?: () => void;
  onCancelButton?: () => void;
  onPrimaryButton?: () => void;
  visibility?: boolean;
  extraClasses?: string;
  content: string | JSX.Element;
}

export default function SpotModal({
  id,
  title,
  footerPrimaryButtonText,
  footerCancelButtonText,
  onCancelButton,
  onCloseButton,
  onPrimaryButton,
  visibility = false,
  extraClasses = '',
  content,
}: SpotModalProps): React.ReactElement {
  return (
    <div id={id} className={`spot-modal__overlay ${extraClasses}`} style={{ display: visibility ? 'block' : 'none' }}>
      <div className="spot-modal">
        <div className="spot-modal__header">
          <div className="spot-modal__titles">
            <h2 className="spot-modal__title">{title}</h2>
          </div>
          <button className="spot-modal__header-cancel-button" data-automation-id={'modal-cancel-button'} onClick={onCloseButton}>
            <CancelIcon />
          </button>
        </div>
        <div className="spot-modal__content">
          <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top"></div>
          <div className="spot-modal__content-wrapper">
            <div className="spot-modal__copy">{content}</div>
          </div>
          <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom"></div>
        </div>
        <div className="spot-modal__footer">
          <button
            className="spot-modal__footer-cancel-button"
            onClick={onCancelButton}
            style={{ visibility: footerCancelButtonText ? undefined : 'hidden' }}
          >
            {footerCancelButtonText}
          </button>
          {footerPrimaryButtonText && (
            <button className="spot-button spot-button--primary" onClick={onPrimaryButton}>
              {footerPrimaryButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
