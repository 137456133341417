import { PimsModel } from './pimsModel';
import { practiceConnectApi } from '../practiceConnectApi';
import { AxiosResponse } from 'axios';
import constants from '../../../../utils/constants';

export const DATAPOINT_SOURCE = 'Datapoint';

export const getSupportedPims = (): Promise<AxiosResponse<PimsModel[]>> => {
  return practiceConnectApi.get(`${constants.PROJECT_CONNECT_API_BASE_URL}/v1/pims`);
};
