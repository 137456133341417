import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { LoggedInUserContext } from '../../components/contexts/LoggedInUserContext';
import CONSTANTS from '../../utils/constants';
import { getAvailableApplications } from '../../services/http/practiceConnect/applications/applicationsService';
import EnrollmentSearchingForAccount from '../../components/Enrollment/EnrollmentMain/EnrollmentSearchingForAccount';

require('./LoginRoute.scss');

interface IParams {
  application: string;
}

LoginRoute.propTypes = {
  redirect: PropTypes.string,
};

/**
 * The Login Route is a simple styler and container for the CFS SSO iFrame. It pings the iFrame regularly to check if
 * authentication has been completed or not. Actual Auth handling is done by the Auth component.
 * The relationship between this component's postMessage and Auth's eventListener could use some clarification.
 * @param {string} redirect Once a user is logged in, we will redirect the user to this path.
 * @returns {*}
 * @constructor
 */
export default function LoginRoute({ redirect, action = 'signIn' }: { redirect?: string; action?: string }): JSX.Element {
  const { loggedInUser } = useContext(LoggedInUserContext);
  const { application } = useParams<IParams>();

  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  if (loggedInUser !== null && loggedInUser.id && !loggedInUser.error) {
    if (application) {
      getAvailableApplications().then((response) => {
        if (response.data.find((app) => app.id === application)) {
          localStorage.setItem(CONSTANTS.LOCAL_STORAGE_APPLICATION_ID, application);
        } else {
          localStorage.setItem(CONSTANTS.LOCAL_STORAGE_APPLICATION_ID, CONSTANTS.UNKNOWN_APPLICATION);
        }
      });
    } else {
      localStorage.setItem(CONSTANTS.LOCAL_STORAGE_APPLICATION_ID, CONSTANTS.UNKNOWN_APPLICATION);
    }
    return <Redirect to={redirect || '/enrollment'} />;
  } else if (loggedInUser && loggedInUser.error) {
    return <Redirect to={'/problem'} />;
  } else {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }

    return <EnrollmentSearchingForAccount></EnrollmentSearchingForAccount>;
  }
}
