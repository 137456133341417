import React, { useEffect, useState } from 'react';
import EnrollmentHeader from '../EnrollmentHeader/EnrollmentHeader';
import EnrollmentLeftPane from '../EnrollmentLeftPane/EnrollmentLeftPane';
import EnrollmentRightPane from '../EnrollmentRightPane/EnrollmentRightPane';
import { EnrollmentDataContext } from '../../contexts/EnrollmentDataContext';
import { PracticeModel } from '../../../services/http/practiceConnect/practice/practiceModel';
import { getMyEnrollments } from '../../../services/http/practiceConnect/enrollment/enrollmentService';
import { ExistingEnrollmentsResponse } from '../../../services/http/practiceConnect/enrollment/models';
import EnrollmentSearchingForAccount from './EnrollmentSearchingForAccount';
import CONSTANTS from '../../../utils/constants';

require('./EnrollmentMain.scss');

function EnrollmentMain() {
  // Storing these state values here so that they can be preserved in a context when a user navigates between screens
  // after entering data
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactPosition, setContactPosition] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [eulaContactFirstName, setEulaContactFirstName] = useState('');
  const [eulaContactLastName, setEulaContactLastName] = useState('');
  const [eulaContactPosition, setEulaContactPosition] = useState('');
  const [eulaContactPhoneNumber, setEulaContactPhoneNumber] = useState('');
  const [eulaContactEmail, setEulaContactEmail] = useState('');
  const [eulaContactBusinessDecisions, setEulaContactBusinessDecisions] = useState(false);
  const [eulaContactReadEula, setEulaContactReadEula] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState<PracticeModel>({
    name: '',
    city: '',
    address1: '',
    address2: '',
    region: '',
    country: '',
    zip: '',
  });
  const [sapId, setSapId] = useState('');
  const [pims, setPims] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMyEnrollments()
      .then((enrollments: ExistingEnrollmentsResponse) => {
        const enrolledApplications = enrollments.map((enrollment) => enrollment.application.id);
        if (enrolledApplications.includes('enterprise')) {
          window.location.href = CONSTANTS.ENTERPRISE_REDIRECT_URL;
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(`Failed to fetch enrollments, user may be unable to proceed`, error);
        window.location.href = '/logout';
      });
  }, []);

  if (isLoading) {
    return <EnrollmentSearchingForAccount />;
  }

  return (
    <EnrollmentDataContext.Provider
      value={{
        setContactFirstName: (name: string) => {
          setContactFirstName(name);
        },
        contactFirstName,
        setContactLastName: (name: string) => {
          setContactLastName(name);
        },
        contactLastName,
        setContactPosition: (position: string) => {
          setContactPosition(position);
        },
        contactPosition,
        setContactEmail: (email: string) => {
          setContactEmail(email);
        },
        contactEmail,
        setContactPhone: (phone: string) => {
          setContactPhone(phone);
        },
        contactPhone,
        setEulaContactFirstName: (firstName: string) => {
          setEulaContactFirstName(firstName);
        },
        eulaContactFirstName,
        setEulaContactLastName: (lastName: string) => {
          setEulaContactLastName(lastName);
        },
        eulaContactLastName,
        setEulaContactPosition: (position: string) => {
          setEulaContactPosition(position);
        },
        eulaContactPosition,
        setEulaContactPhoneNumber: (phoneNumber: string) => {
          setEulaContactPhoneNumber(phoneNumber);
        },
        eulaContactPhoneNumber,
        setEulaContactEmail: (email: string) => {
          setEulaContactEmail(email);
        },
        eulaContactEmail,
        setEulaContactBusinessDecisions: (decisions: boolean) => {
          setEulaContactBusinessDecisions(decisions);
        },
        eulaContactBusinessDecisions,
        setEulaContactReadEula: (decisions: boolean) => {
          setEulaContactReadEula(decisions);
        },
        eulaContactReadEula,
        setSelectedPractice: (practice: PracticeModel) => {
          setSelectedPractice(practice);
        },
        selectedPractice,
        setSapId: (sapId: string) => {
          setSapId(sapId);
        },
        sapId,
        setPims: (pims: string) => {
          setPims(pims);
        },
        pims,
      }}
    >
      <div className="enrollment-main-container" data-automation-id={'enrollmentMainContainer'}>
        <div className={'enrollment-header'} data-automation-id={'enrollmentHeader'}>
          <EnrollmentHeader />
        </div>
        <div className={'enrollment-main-content'} data-automation-id={'enrollmentMainContent'}>
          <div className={'enrollment-left-pane'} data-automation-id={'enrollmentLeftPane'}>
            <EnrollmentLeftPane />
          </div>
          <div className={'enrollment-right-pane'} data-automation-id={'enrollmentRightPane'}>
            <EnrollmentRightPane isLoading={isLoading} />
          </div>
        </div>
      </div>
    </EnrollmentDataContext.Provider>
  );
}

export default EnrollmentMain;
