import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import translate from 'counterpart';
import withFormValidation from '../validation/withFormValidation';
import { createSpotClasses, Spot } from '../../';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';

require('./SpotFormInput.scss');

SpotFormInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  validateData: PropTypes.func,
  setShowError: PropTypes.func,
  textAreaRows: PropTypes.string,
  textAreaResize: PropTypes.oneOf(['none', 'initial', 'inherit', 'both', 'vertical', 'horizontal']),
  labelIcon: PropTypes.element,
  labelIconLink: PropTypes.string,
};

SpotFormInput.defaultProps = {
  initialValue: '',
  value: '',
  type: 'text',
  validateData: () => ({ isValid: true }),
  onChange: () => {},
};

SpotFormInput.testIds = {
  inputElement: 'spot-form-input__html-input',
  blurConfirmation: 'spot-form-input__blur-confirmation',
};

/**
 * SpotFormInput is a direct port from the legacy application that handles a number of features of form input handling
 * including onBlur functions and field content validation.
 * @param id {string} A unique identifier for the element.
 * @param type {string} A type property that is propagated to the input element in the DOM. "text" by default.
 * @param label {string} The text content of the primary label of the input element
 * @param subLabel {string} The text content of the secondary label of the input element - a subtitle, if you will.
 * @param placeholder {string} The placeholder text that fills the input element (in the case of a text input)
 * @param disabled {boolean} Whether or not the input element can be interacted with.
 * @param initialValue {string} The value occupying the input element when the component mounts.
 * @param textAreaRows {string} Used with type='textarea'. Sets the default number of rows in a textarea
 * @param textAreaResize {string} Used with type='textarea'. Restrict resizing to 'none', 'vertical' or 'horizontal'
 * @param props {{}}
 * @returns {*}
 * @constructor
 */
export function SpotFormInput({
  id,
  type,
  label,
  subLabel,
  placeholder,
  disabled,
  initialValue,
  textAreaRows,
  textAreaResize,
  labelIcon,
  labelIconLink,
  ...props
}) {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const [successMessage, setSuccessMessage] = useState({ text: '', styleModifier: '' });
  const [pendingUpdate, setPendingUpdate] = useState(false);

  /*
    We track this in a useEffect to allow prop changes to correctly influence the value in the component in addition to
    allowing the user to type in the box. As long as the initialValue in props stays the same, user input is preserved.
     */
  useEffect(() => setValue(initialValue), [initialValue]);
  useEffect(() => {
    setIsValid(props.validateData(value).isValid);
  }, [value, props]);

  function onChange(event) {
    const newValue = event.target.value;
    setValue(newValue);
    // setPendingUpdate(true);
    setSuccessMessage({ text: '', styleModifier: '' });
    props.onChange(newValue);
  }

  function onBlur(event) {
    if (!props.onBlur) {
      return;
    } else {
      props.onBlur();
    }
  }

  const showErrorBox = props.showError && props.errorText;

  return (
    <div className={'form-relative'}>
      {label && <label className={createSpotClasses(Spot.block.form, Spot.element.label)}>{translate(label)} </label>}
      {labelIcon && (
        <a href={labelIconLink}>
          <img style={{ width: 18 }} src={labelIcon} alt="React Logo" />
        </a>
      )}
      {subLabel && <span className={createSpotClasses(Spot.block.form, Spot.element.microcopy)}>{translate(subLabel)}</span>}
      <span className={`form-blur-confirmation${successMessage.styleModifier}`} data-testid={SpotFormInput.testIds.blurConfirmation}>
        {successMessage.text}
      </span>
      {type === 'textarea' && (
        <textarea
          className={classNames(
            createSpotClasses(Spot.block.form, Spot.element.textarea),
            'spot-textarea-text',
            showErrorBox ? 'input-error-border' : '',
          )}
          rows={textAreaRows}
          style={textAreaResize ? { resize: textAreaResize } : { resize: 'initial' }}
          value={value}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={props.onKeyPress}
          data-testid={SpotFormInput.testIds.inputElement}
          maxLength={props.maxLength || ''}
        />
      )}
      {type === 'text' && (
        <input
          className={classNames(createSpotClasses(Spot.block.form, Spot.element.input), showErrorBox ? 'input-error-border' : '')}
          type={type}
          disabled={disabled}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={props.onKeyPress}
          onBlur={onBlur}
          data-testid={SpotFormInput.testIds.inputElement}
          maxLength={props.maxLength || ''}
          onInput={props.onInput || undefined}
        />
      )}
      {type === 'mask' && (
        <NumberFormat
          {...props}
          className={classNames(createSpotClasses(Spot.block.form, Spot.element.input), showErrorBox ? 'input-error-border' : '')}
          type={type}
          disabled={disabled}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={props.onKeyPress}
          onBlur={onBlur}
          data-testid={SpotFormInput.testIds.inputElement}
          maxLength={props.maxLength || ''}
        />
      )}
    </div>
  );
}

const ValidatedFormInput = withFormValidation(SpotFormInput);
ValidatedFormInput.testIds = SpotFormInput.testIds;
export default ValidatedFormInput;
