import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import EnrollmentMain from '../components/Enrollment/EnrollmentMain/EnrollmentMain';

import { LoggedInUserContext } from '../components/contexts/LoggedInUserContext';
import LogoutPage from '../components/Logout/LogoutPage';
import LoginRoute from './Login/LoginRoute';
import EnrollmentCallUs from '../components/Enrollment/EnrollmentRightPane/EnrollmentCallUs/EnrollmentCallUs';
import EulaConfirmationPage from '../components/Enrollment/EnrollmentRightPane/EnrollmentFindPractice/EnrollmentEula/EulaConfirmationPage/EulaConfirmationPage';

export default function Routes(): React.ReactElement {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={'/login'} component={LoginRoute} />
        <Route path={'/signUp'} component={() => <LoginRoute action={'signUp'} />} />
        {/*<Route path={'/enrollment'} component={withAuthenticationRequired(EnrollmentMain)} />*/}
        <AuthorizedRoute exact path={'/enrollment'} component={EnrollmentMain} />
        <Route exact path={'/logout'} component={LogoutPage} />
        <Route path={'/problem'} component={EnrollmentCallUs} />
        <AuthorizedRoute permission={''} exact path={'/confirmation'} component={EulaConfirmationPage} />
        <Route path={'/:application/login'} component={LoginRoute} />
        <Route path={'/:application/signUp'} component={() => <LoginRoute action={'signUp'} />} />
        <Route path={'/:application'} component={LoginRoute} />
        <AuthorizedRoute permission={''} exact path={'/'} component={EnrollmentMain} />
        {/*<Route path={'/'} component={Main} />*/}
      </Switch>
    </BrowserRouter>
  );
}

function AuthorizedRoute({ ...routeProps }) {
  const { loggedInUser } = useContext(LoggedInUserContext);

  if (loggedInUser) {
    return <Route {...routeProps} />;
  } else {
    return <LoginRoute action={'login'} />;
  }
}
