import { UserModel } from './userModel';
import { practiceConnectApi } from '../practiceConnectApi';
import { ApiStatusCodes } from '../errors/practiceConnectError';
import constants from '../../../../utils/constants';
import { OnboardingPracticeModel } from '../practice/onboardingPracticeModel';

export const getMyUser = async (): Promise<UserModel> => {
  return practiceConnectApi.get(`${constants.PROJECT_CONNECT_API_BASE_URL}/v1/user`).then((response) => response.data);
};

export const createMyUser = async (email: string | null = null, username: string | null = null): Promise<UserModel> => {
  return practiceConnectApi
    .post(`${constants.PROJECT_CONNECT_API_BASE_URL}/v1/user`, { email, username })
    .then((response) => response.data);
};

export const getOrCreateMyUser = async (email?: string, username?: string): Promise<UserModel> => {
  try {
    const existingUser = await getMyUser();
    return existingUser;
  } catch (error) {
    if (error.code !== ApiStatusCodes.NOT_FOUND) {
      throw error;
    }
  }

  return createMyUser(email, username);
};

export const onboardUser = async (username: string, application: string, practices: OnboardingPracticeModel[]): Promise<unknown> => {
  return practiceConnectApi.post(`${constants.PROJECT_CONNECT_API_BASE_URL}/v1/onboarding/${application}`, { username, practices });
};
