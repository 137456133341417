import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createSpotClasses, Spot } from '../../index';

/**
 * withFormValidation is a higher-order-component wrapping any component that needs some kind of validation procedure
 * and error message display. It allows the component to take an array of validation rules (functions) and is granted
 * a validateData prop function that it calls with a 'data' argument. This data is run through each validation function
 * and if any of those validation functions return error text it is displayed below the component in formatted text.
 *
 * ShowError and errorText are exposed to allow the components implementing this HOC to override any of the default text
 * or behaviour.
 * @param Component
 * @returns {function(*): *}
 */
export default function withFormValidation(Component) {
    FormValidation.propTypes = {
        validationRules: PropTypes.arrayOf(PropTypes.func),
        showError: PropTypes.bool,
        errorText: PropTypes.string
    };

    FormValidation.defaultProps = {
        validationRules: []
    };

    function FormValidation(props) {
        const [errorText, setErrorText] = useState('');
        const [showError, setShowError] = useState(false);

        /**
         * For use by the implementing component. Allows component to validate itself for on-click or on-blur submission
         * @param data: The data to be validated. Could be many different shapes.
         * @returns {{isValid: boolean}}
         */
        function validateDataLocal(data) {
            const errText = props.validationRules.map((validationFunc) => validationFunc(data)).find((text) => text) || '';
            setErrorText(errText);
            return { isValid: !errText };
        }

        const errorTextDisplay = errorText || props.errorText;
        const doShowError = (showError || props.showError) && errorTextDisplay;
        const outerDivClassNames = createSpotClasses(Spot.block.form, Spot.element.fieldGroup);
        if (doShowError) {
            outerDivClassNames.concat('spot-form--error');
        }
        return (
            <div className={outerDivClassNames}>
                <Component validateData={validateDataLocal} setShowError={setShowError} {...props} />
                {doShowError && (
                    <span className={createSpotClasses(Spot.block.form, Spot.element.fieldError)} role="alert">
                        <span className={createSpotClasses(Spot.block.form, Spot.element.fieldErrorText)}>{errorTextDisplay}</span>
                    </span>
                )}
            </div>
        );
    }
    return FormValidation;
}
