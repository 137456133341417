import React, { useState, useEffect, useRef, useContext } from 'react';
import translate from 'counterpart';
import { ValidatedFormInput, Form } from '../../../../Spot/index';
import HelpIcon from '../../../../images/HelpIcon/HelpIcon';
import SpotPopover from '../../../../Spot/SpotPopover/SpotPopover';
import EnrollmentHelp from './EnrollementHelp/EnrollmentHelp';
import EnrollmentSearchingForPractice from './EnrollmentSearchingForPractice/EnrollmentSearchingForPractice';
import EnrollmentPracticeFound from './EnrollmentPracticeFound/EnrollmentPracticeFound';
import CONSTANTS from '../../../../utils/constants';
import { getPracticesBySapId } from '../../../../services/http/practiceConnect/practice/practiceService';
import { PracticeModel } from '../../../../services/http/practiceConnect/practice/practiceModel';
import EnrollmentRightPaneFooter from '../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
import EnrollmentCallUs from '../EnrollmentCallUs/EnrollmentCallUs';
import { EnrollmentDataContext } from '../../../contexts/EnrollmentDataContext';
import { PracticeConnectError } from '../../../../services/http/practiceConnect/errors/practiceConnectError';
import { PracticeResponseError } from '../../../../services/http/practiceConnect/practice/practiceResponseError';
import InlineMessage from '../../../SystemMessages/InlineMessage';
import { getCurrentApplication } from '../../../../utils/application';

require('./EnrollmentFindPractice.scss');

function EnrollmentFindPractice(): React.ReactElement {
  const [accountNumber, setAccountNumber] = useState('');
  const [invoiceTotal, setInvoiceTotal] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [invoiceTotalError, setInvoiceTotalError] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [validationOn, setValidationOn] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSearching, setShowSearching] = useState(false);
  const [foundPractices, setFoundPractices] = useState<PracticeModel[]>([]);
  const [showCallUs, setShowCallUs] = useState(false);
  const [showAttemptsRemaining, setShowAttemptsRemaining] = useState(false);
  const [attemptsRemaining, setAttemptsRemaining] = useState(3);

  const scrollRef = useRef<HTMLHeadingElement>(null);
  const { setSapId } = useContext(EnrollmentDataContext);

  const update = () => {
    setWindowWidth(window.innerWidth);
    if (scrollRef?.current && window.innerWidth < CONSTANTS.MINIMUM_SCREEN_SIZE_RESPONSIVE) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  window.addEventListener('resize', update);

  useEffect(() => {
    const lockoutTime = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_LOCKOUT_KEY);
    if (lockoutTime) {
      const lockout = new Date(lockoutTime);
      const now = new Date();
      const minsPassed = (now.getTime() - lockout.getTime()) / (1000 * 60);
      if (minsPassed > 10) {
        localStorage.removeItem(CONSTANTS.LOCAL_STORAGE_LOCKOUT_KEY);
      } else {
        setAttemptsRemaining(0);
        setShowCallUs(true);
      }
    }
  }, []);

  useEffect(() => {
    setAccountNumberError(checkAccountNumber(accountNumber));
    setInvoiceTotalError(checkInvoiceTotal(invoiceTotal));
  }, [accountNumber, invoiceTotal]);

  useEffect(() => {
    setFormValid(!!(!accountNumberError && accountNumber && !invoiceTotalError && invoiceTotal));
  }, [accountNumberError, accountNumber, invoiceTotalError, invoiceTotal]);

  // Leaving this here in case we want to additional validation int he future.
  const checkAccountNumber = (input: string) => {
    return '';
  };

  const checkInvoiceTotal = (input: string) => {
    let temp = input.replace('$', '');
    temp = temp.replace(new RegExp(',', 'g'), '');
    const reg = /(?:^\d{1,3}(?:\.?\d{3})*(?:,\d{2})?$)|(?:^\d{1,3}(?:,?\d{3})*(?:\.\d{2})?$)/;
    if (temp.match(reg)! == null && temp !== '') {
      return translate('paymentAmountMustBeANumber');
    } else {
      return '';
    }
  };

  const handleSubmit = async () => {
    if (formValid) {
      setShowSearching(true);
      setShowAttemptsRemaining(false);
      const invoicePrice = Number(invoiceTotal.replace(/[^0-9.]+/g, ''));
      const application = getCurrentApplication();
      getPracticesBySapId(accountNumber, invoicePrice, application)
        .then((response) => {
          const practices: PracticeModel[] = response.data;
          setShowSearching(false);

          if (!practices) {
            setShowCallUs(true);
          } else if (practices?.length < 1) {
            setShowCallUs(true);
          } else {
            setFoundPractices(practices);
            setSapId(accountNumber);
          }
        })
        .catch((error: PracticeConnectError<PracticeResponseError>) => {
          setShowSearching(false);
          if (error.code === 429) {
            // Too many requests
            setAttemptsRemaining(0);
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE_LOCKOUT_KEY, new Date().toString());
            setShowCallUs(true);
          } else if (error.body && error.body.attemptsRemaining) {
            setShowAttemptsRemaining(true);
            setAttemptsRemaining(error.body.attemptsRemaining);
            setShowCallUs(false);
          } else {
            setShowCallUs(true);
          }
        });
    } else {
      setValidationOn(true);

      //If either field is empty on submit, show the error on the respective field.
      if (!accountNumber) {
        setAccountNumberError(translate('requiredField'));
      }
      if (!invoiceTotal) {
        setInvoiceTotalError(translate('requiredField'));
      }
    }
  };

  if (showSearching && foundPractices?.length < 1) {
    return <EnrollmentSearchingForPractice />;
  } else if (!showSearching && foundPractices?.length > 0) {
    return <EnrollmentPracticeFound practices={foundPractices} />;
  } else if (!showSearching && showCallUs) {
    return <EnrollmentCallUs showBackButton={attemptsRemaining > 0} previousPage={<EnrollmentFindPractice />} />;
  }

  // TODO: This is not the way that this should be done. The Spot Form fields need to be refactored to include a
  // TODO: an icon that sizes correctly when needed.
  const findHelpButtonClass = () => {
    if (invoiceTotalError && !accountNumberError) {
      return 'help-image-1-show-error-bottom';
    } else if (!invoiceTotalError && accountNumberError) {
      return 'help-image-1-show-error';
    } else if (invoiceTotalError && accountNumberError) {
      return 'help-image-1-show-error-both';
    }
    return 'help-image-1';
  };

  const retryErrorMessage = <span>{translate('attemptsRemaining', { count: attemptsRemaining })}</span>;
  const retryErrorProps = {
    title: translate('noMatchFound'),
    message: retryErrorMessage,
  };

  return (
    <div className={'enrollment-find-practice-parent-container'} ref={scrollRef}>
      <div className={'enrollment-find-practice-container-scroll'}>
        <div className={'enrollment-find-practice-container'}>
          <div className={'content-title'}>{translate('letsFindYourPractice')}</div>
          <div className={'content-image'}>
            <img className={'house-image'} src={'/house.png'} alt={translate('altText.house')} aria-hidden={true} />
          </div>
          <div className={'content-input-form-and-text'}>
            {showAttemptsRemaining && (
              <div className={'content-input-form-inline-error'} data-automation-id={'inline-practice-attempts-error'}>
                <InlineMessage {...retryErrorProps} priority={'high'} level={'danger'} />
              </div>
            )}
            <div className={'content-input-form'}>
              <Form>
                <div className="spot-form__field-group spot-form__field-group--vertical">
                  <div className="spot-form__field-group">
                    <ValidatedFormInput
                      {...{
                        onChange: setAccountNumber,
                        label: 'idexxAccountNumber',
                        initialValue: '',
                        errorText: accountNumberError,
                        placeholder: '',
                        showError: validationOn,
                        id: 'account-number-test-id',
                        pattern: '[a-zA-Z0-9]{0,10}',
                        maxLength: '10',
                        onInput: (e: any) => {
                          const reg = /^[a-zA-Z0-9\d]*$/;
                          if (!e.target.value.match(reg)) {
                            e.target.value = e.target.value.slice(0, -1);
                          }
                        },
                      }}
                    />
                  </div>

                  <div className="spot-form__field-group">
                    <ValidatedFormInput
                      {...{
                        onChange: setInvoiceTotal,
                        label: 'paymentAmount',
                        subLabel: 'withinTheLastThreeMonths',
                        placeholder: '$',
                        errorText: invoiceTotalError,
                        showError: validationOn,
                        id: 'invoice-amount-test-id',
                        type: 'mask',
                        thousandSeparator: ',',
                        prefix: '$',
                        allowNegative: false,
                      }}
                    />
                  </div>
                </div>
              </Form>
              <div className={findHelpButtonClass()}>
                <SpotPopover
                  popoverWrapperClassName={'popover-wrapper'}
                  width={windowWidth < CONSTANTS.MINIMUM_SCREEN_XL_POPOVER ? 'large' : 'xlarge'}
                  parentRef={scrollRef}
                  TriggerComponent={<HelpIcon />}
                  defaultDrawLocation={'bottom'}
                  isFullScreen={windowWidth < CONSTANTS.MINIMUM_SCREEN_SIZE_POPOVER ? true : false}
                >
                  <EnrollmentHelp helpImage={'SAP.png'} text={translate('yourIdexxAccountNumber')} />
                </SpotPopover>
              </div>
              <div className={invoiceTotalError ? 'help-image-2-show-error' : 'help-image-2'}>
                <SpotPopover
                  popoverWrapperClassName={'popover-wrapper'}
                  width={windowWidth < CONSTANTS.MINIMUM_SCREEN_XL_POPOVER ? 'large' : 'xlarge'}
                  parentRef={scrollRef}
                  TriggerComponent={<HelpIcon />}
                  defaultDrawLocation={'bottom'}
                  isFullScreen={windowWidth < CONSTANTS.MINIMUM_SCREEN_SIZE_POPOVER ? true : false}
                >
                  <EnrollmentHelp helpImage={'paymentAmount.png'} text={translate('idexxPaymentAmount')} />
                </SpotPopover>
              </div>
            </div>
            <div className={'content-contact-us'}>
              {translate('dontHaveTheAbove')}
              <div>
                <a href={CONSTANTS.SUPPORT_PHONE_HREF}>{CONSTANTS.SUPPORT_PHONE_TEXT}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'enrollment-find-practice-footer'}>
        <EnrollmentRightPaneFooter submitFunction={handleSubmit} buttonText={translate('startConnection')} />
      </div>
    </div>
  );
}

export default EnrollmentFindPractice;
