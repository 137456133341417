import React from 'react';
require('./EnrollmentHelp.scss');

interface IProps {
  helpImage: string;
  text: string;
}

function EnrollmentHelp({ helpImage, text }: IProps) {
  return (
    <div className={'enrollment-help'}>
      <div className={'enrollment-help-text'}>{text}</div>
      <div className={'enrollment-help-image'}>
        <img src={helpImage} style={{ width: '100%' }} alt={'Help'} />
      </div>
    </div>
  );
}

export default EnrollmentHelp;
