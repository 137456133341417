import React from 'react';

require('./EnrollmentEulaFooter.scss');

interface IProps {
  submitFunction: () => void;
  buttonText?: string;
  secondaryButtonText?: string;
  secondaryButtonFunction?: () => void;
}

function EnrollmentEulaFooter({ submitFunction, buttonText, secondaryButtonText, secondaryButtonFunction }: IProps) {
  return (
    <div className={'enrollment-eula-footer-container'}>
      <div className={'enrollment-eula-footer-button-container'}>
        {secondaryButtonText && (
          <div
            className={'enrollment-eula-footer-secondary-button'}
            onClick={secondaryButtonFunction}
            data-automation-id={'enrollmentEulaFooterSecondaryButton'}
          >
            {secondaryButtonText}
          </div>
        )}
        {buttonText && (
          <button className={'enrollment-eula-footer-button'} onClick={submitFunction} data-automation-id={'enrollmentEulaFooterButton'}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default EnrollmentEulaFooter;
