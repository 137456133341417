import React, { useState } from 'react';
import EulaConfirmationPageFooter from './EulaConfirmationPageFooter/EulaConfirmationPageFooter';
import CONSTANTS from '../../../../../../utils/constants';
require('./EulaConfirmationPage.scss');

interface IProps {
  error: boolean;
}

function EulaConfirmationPage({ error }: IProps) {
  const [fadeOut, setFadeOut] = useState(false);

  // This is not the best way to handle this, but I could not identify a better way to shrink the top bar.
  const update = () => {
    const top: HTMLElement = document.getElementById('confirmation-top')!;
    const bottom: HTMLElement = document.getElementById('confirmation-bottom')!;

    if (top && bottom && bottom.clientHeight === 275) {
      top.style.height = `${window.innerHeight - 275}px`;
    } else if (top) {
      top.style.height = '44%';
    }
  };

  window.addEventListener('load', update);
  window.addEventListener('resize', update);

  const submitFunction = () => {
    // TODO: Make this more dynamic based on application information.
    setFadeOut(true);
    setTimeout(function () {
      window.location.href = CONSTANTS.ENTERPRISE_REDIRECT_URL;
    }, 1300);
  };

  // If the user is still on the page automatically go to landing page. V1 goes to enterprise.
  setTimeout(function () {
    submitFunction();
  }, 4000);

  update();

  return (
    <div className={!fadeOut ? 'confirmation-page-no-fade' : 'confirmation-page-fade'}>
      <div className={'enrollment-confirmation-container'}>
        <div className={'enrollment-confirmation-top'} id={'confirmation-top'}>
          <div className={'content-image'}>
            <img className={'confirmation-image'} src={'/confirmation.png'} alt={'houseImage'} />
          </div>
        </div>
        <div className={'enrollment-confirmation-footer'} id={'confirmation-bottom'}>
          <EulaConfirmationPageFooter buttonText={''} submitFunction={submitFunction} />
        </div>
      </div>
    </div>
  );
}

export default EulaConfirmationPage;
