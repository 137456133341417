import React from 'react';
require('./HelpIcon.scss');

export default function HelpIcon({ fill }: { fill?: string }): JSX.Element {
  return (
    <>
      <svg className={'help-icon'} width="32" height="32" fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <title>Help</title>
        <path
          d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm-.019-5a1.79 1.79 0 0 0 1.781-1.8c0-.994-.797-1.8-1.78-1.8a1.79 1.79 0 0 0-1.782 1.8c0 .994.797 1.8 1.781 1.8zm.094-18a4.76 4.76 0 0 0-4.478 3.2l-.397 1.13 2.238.801.396-1.131a2.384 2.384 0 0 1 2.24-1.6c1.31 0 2.376 1.076 2.376 2.4 0 .758-.291 1.007-1.13 1.616-1.027.744-2.433 1.764-2.433 4.384V19h2.375v-1.2c0-1.388.573-1.804 1.442-2.434.895-.649 2.12-1.538 2.12-3.566 0-2.647-2.13-4.8-4.75-4.8z"
          fillRule="evenodd"
        />
      </svg>
    </>
  );
}
