import React from 'react';
import PropTypes from 'prop-types';
require('./SpotFormColumn.scss');

SpotFormColumn.propTypes = {
    weight: PropTypes.number,
    children: PropTypes.node
};

SpotFormColumn.defaultProps = {
    weight: 1
};

/**
 * Wrapper for form elements meant to be divided into columns. Weight tells the column to take up more space relative
 * to other columns.
 */
export default function SpotFormColumn(props) {
    return (
        <div className="form-column" style={{ width: `${props.weight * 100}%` }}>
            {props.children}
        </div>
    );
}
