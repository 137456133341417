import React from 'react';
import './IdexxLogo.scss';
/**
 * HeaderLogo is a colored SVG IDEXX Logo of 100 pixel width.
 * @returns {*}
 */
export default function IdexxLogo(): JSX.Element {
  return (
    <>
      <svg className={'header-logo'} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.46 77.89">
        <title>IDEXX Logo RGB SEP2015</title>
        <polygon className="cls-1" points="0 0 24.66 0 24.66 77.88 0 77.88 0 0 0 0 0 0 0 0" />
        <polygon
          className="cls-1"
          points="146.95 77.88 231.93 77.88 231.93 59.16 170.83 59.16 170.83 43.42 146.95 43.42 146.95 77.88 146.95 77.88 146.95 77.88 146.95 77.88"
        />
        <path
          className="cls-1"
          d="M96.84,57.88H58.32V44.47H34.44V77.88h66.28c23.87,0,38.05-10.75,38.05-30V44.47H114.88c-.28,10.6-8,13.41-18,13.41Z"
        />
        <path
          className="cls-1"
          d="M58.32,19.36H96.84c10,0,17.72,2.8,18,13.31h23.89V30c0-19.27-14.18-30-38.05-30H34.44V32.67H58.32V19.36Z"
        />
        <polygon
          className="cls-1"
          points="231.95 18.08 231.95 0 146.95 0 146.95 32.67 170.83 32.67 170.83 18.08 231.95 18.08 231.95 18.08"
        />
        <polygon className="cls-2" points="179.02 31.6 230.9 31.6 230.9 45.51 179.02 45.51 179.02 31.6 179.02 31.6" />
        <polygon
          className="cls-1"
          points="394.17 44.47 351.74 44.47 328.33 69.98 304.93 44.47 262.58 44.47 231.93 77.88 263.33 77.88 283.73 53.8 304.19 77.88 352.48 77.88 372.9 53.8 393.33 77.89 425.46 77.89 394.17 44.47 394.17 44.47"
        />
        <polygon
          className="cls-1"
          points="328.33 7.91 351.06 32.67 394.77 32.67 424.72 0 393.33 0 372.91 24.09 352.49 0.01 304.21 0 283.73 24.09 263.33 0.01 231.93 0.01 261.89 32.67 305.63 32.67 328.33 7.91 328.33 7.91"
        />
      </svg>
    </>
  );
}
