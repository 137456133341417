import React from 'react';
import EnrollmentFindPractice from './EnrollmentFindPractice/EnrollmentFindPractice';
import './EnrollmentRightPane.scss';
import { Spot, SpotIcon } from '../../../Spot';

function EnrollmentRightPane({ isLoading }: { isLoading?: boolean }): JSX.Element {
  return (
    <div className={'enrollment-right-pane-container'}>
      <div className={'enrollment-right-pane-header'} />
      {isLoading && (
        <div className={'loading-spinner-container'}>
          <SpotIcon
            title={'spinner'}
            iconName={Spot.icons.spinner}
            data-automation-id={'findingPracticeLoadingSpinner'}
            className={'spot-loading-spinner spot-loading-spinner--large'}
          />
        </div>
      )}
      {!isLoading && <EnrollmentFindPractice />}
    </div>
  );
}

export default EnrollmentRightPane;
