import React, { useEffect, useRef, useState, useContext } from 'react';
import translate from 'counterpart';
import EnrollmentRightPaneFooter from '../../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
import EnrollmentPracticeFound from '../EnrollmentPracticeFound/EnrollmentPracticeFound';
import { Form, ValidatedFormInput } from '../../../../../Spot/Form';
import { EnrollmentDataContext } from '../../../../contexts/EnrollmentDataContext';
import CONSTANTS from '../../../../../utils/constants';
import { validatePhone, validateEmail } from '../../../../../utils/validation';
import EnrollmentEula from '../EnrollmentEula/EnrollmentEula';

require('./EnrollmentTechnicalContact.scss');

interface IProps {
  practices: any;
}

function EnrollmentTechnicalContact({ practices }: IProps): JSX.Element {
  const {
    contactFirstName,
    setContactFirstName,
    contactLastName,
    setContactLastName,
    contactPosition,
    setContactPosition,
    contactEmail,
    setContactEmail,
    contactPhone,
    setContactPhone,
  } = useContext(EnrollmentDataContext);
  const [previous, setPrevious] = useState(false);
  const [contactFirstNameError, setContactFirstNameError] = useState('');
  const [contactLastNameError, setContactLastNameError] = useState('');
  const [contactPositionError, setContactPositionError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');
  const [contactPhoneError, setContactPhoneError] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [validationOn, setValidationOn] = useState(true);
  const [showEulaPage, setShowEulaPage] = useState(false);

  const scrollRef = useRef<HTMLHeadingElement>(null);

  const update = () => {
    if (scrollRef?.current && window.innerWidth < CONSTANTS.MINIMUM_SCREEN_SIZE_RESPONSIVE) {
      scrollRef.current.scrollTo(0, 0);
    }
  };

  window.addEventListener('resize', update);

  useEffect(() => {
    if (contactEmailError) {
      setContactEmailError(checkContactEmail(contactEmail));
    }
    if (contactPhoneError) {
      setContactPhoneError(checkContactPhone(contactPhone));
    }
    if (contactFirstNameError && contactFirstName) {
      setContactFirstNameError('');
    }
    if (contactLastNameError && contactLastName) {
      setContactLastNameError('');
    }
    if (contactPositionError && contactPosition) {
      setContactPositionError('');
    }
  }, [contactEmail, contactPhone, contactFirstName, contactLastName, contactPosition]);

  useEffect(() => {
    setFormValid(
      !!(
        !contactFirstNameError &&
        contactFirstName &&
        !contactLastNameError &&
        contactLastName &&
        !contactPositionError &&
        contactPosition &&
        !contactEmailError &&
        contactEmail &&
        !contactPhoneError &&
        contactPhone
      ),
    );
  }, [
    contactFirstNameError,
    contactFirstName,
    contactLastNameError,
    contactLastName,
    contactPositionError,
    contactPosition,
    contactEmailError,
    contactEmail,
    contactPhoneError,
    contactPhone,
  ]);

  //Check the form status on load in case there are saved values.
  useEffect(() => {
    if (contactEmail) {
      setContactEmailError(checkContactEmail(contactEmail));
    }
    if (contactPhone) {
      setContactPhoneError(checkContactPhone(contactPhone));
    }
    setFormValid(
      !!(
        !contactFirstNameError &&
        contactFirstName &&
        !contactLastNameError &&
        contactLastName &&
        !contactPositionError &&
        contactPosition &&
        !contactEmailError &&
        contactEmail &&
        !contactPhoneError &&
        contactPhone
      ),
    );
  }, []);

  const checkContactEmail = (input: string) => {
    return validateEmail(input);
  };

  const checkContactPhone = (input: string) => {
    return validatePhone(input);
  };

  const handleSubmit = () => {
    if (formValid) {
      setShowEulaPage(true);
    } else {
      setValidationOn(true);
      setShowEulaPage(false);

      //If either field is empty on submit, show the error on the respective field.
      if (!contactFirstName) {
        setContactFirstNameError(translate('requiredField'));
      }
      if (!contactLastName) {
        setContactLastNameError(translate('requiredField'));
      }
      if (!contactPosition) {
        setContactPositionError(translate('requiredField'));
      }
      if (!contactEmail) {
        setContactEmailError(translate('requiredField'));
      } else {
        setContactEmailError(checkContactEmail(contactEmail));
      }
      if (!contactPhone) {
        setContactPhoneError(translate('requiredField'));
      } else {
        setContactPhoneError(checkContactPhone(contactPhone));
      }
    }
  };

  if (previous) {
    return <EnrollmentPracticeFound practices={practices} />;
  }

  if (showEulaPage) {
    return <EnrollmentEula practices={practices} />;
  }

  return (
    <div className={'enrollment-technical-contact-parent-container'} ref={scrollRef}>
      <div className={'enrollment-technical-contact-container-scroll'}>
        <div className={'enrollment-technical-contact-container'}>
          <div className={'technical-contact-content-title-container'}>
            <div className={'technical-contact-content-title'} data-automation-id={'enrollmentTechnicalContactText'}>
              {translate('technicalContactText')}
            </div>
          </div>
          <div className={'content-image'}>
            <img className={'house-image'} src={'/techContact.png'} alt={'technicalContactImage'} />
          </div>
          <div className={'content-input-form-and-text'}>
            <div className={'content-input-form'}>
              <Form>
                <div className="spot-form__field-group spot-form__field-group--vertical">
                  <div className="spot-form__field-group" data-automation-id={'enrollmentTechnicalContactFirstName'}>
                    <ValidatedFormInput
                      {...{
                        onChange: setContactFirstName,
                        label: 'contactFirstName',
                        initialValue: contactFirstName,
                        errorText: contactFirstNameError,
                        showError: validationOn,
                        maxLength: '80',
                        id: 'enrollment-contact-first-name-test-id',
                      }}
                    />
                  </div>

                  <div className="spot-form__field-group" data-automation-id={'enrollmentTechnicalContactLastName'}>
                    <ValidatedFormInput
                      {...{
                        onChange: setContactLastName,
                        label: 'contactLastName',
                        initialValue: contactLastName,
                        errorText: contactLastNameError,
                        showError: validationOn,
                        maxLength: '80',
                        id: 'enrollment-contact-last-name-test-id',
                      }}
                    />
                  </div>

                  <div className="spot-form__field-group" data-automation-id={'enrollmentTechnicalContactPosition'}>
                    <ValidatedFormInput
                      {...{
                        onChange: setContactPosition,
                        label: 'contactPositionTitle',
                        initialValue: contactPosition,
                        errorText: contactPositionError,
                        showError: validationOn,
                        maxLength: '30',
                        id: 'enrollment-contact-position-test-id',
                      }}
                    />
                  </div>

                  <div className="spot-form__field-group" data-automation-id={'enrollmentTechnicalContactEmail'}>
                    <ValidatedFormInput
                      {...{
                        onChange: setContactEmail,
                        label: 'contactEmail',
                        initialValue: contactEmail,
                        errorText: contactEmailError,
                        showError: validationOn,
                        id: 'enrollment-contact-email-test-id',
                        maxLength: '80',
                        onBlur: () => {
                          setContactEmailError(checkContactEmail(contactEmail));
                        },
                      }}
                    />
                  </div>

                  <div className="spot-form__field-group" data-automation-id={'enrollmentTechnicalContactPhone'}>
                    <ValidatedFormInput
                      {...{
                        onChange: setContactPhone,
                        label: 'contactPhone',
                        initialValue: contactPhone,
                        errorText: contactPhoneError,
                        showError: validationOn,
                        id: 'enrollment-contact-phone-test-id',
                        type: 'mask',
                        mask: '_',
                        format: '(###) ###-####',
                        onBlur: () => {
                          setContactPhoneError(checkContactPhone(contactPhone));
                        },
                      }}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className={'enrollment-technical-contact-footer'}>
        <EnrollmentRightPaneFooter
          submitFunction={handleSubmit}
          buttonText={translate('next')}
          secondaryButtonText={translate('previous')}
          secondaryButtonFunction={() => setPrevious(true)}
        />
      </div>
    </div>
  );
}

export default EnrollmentTechnicalContact;
