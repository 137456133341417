import Select, { OptionsType } from 'react-select';
import React, { useState, useEffect } from 'react';
import translate from 'counterpart';
import { createSpotClasses, Spot } from '../../';
import withFormValidation from '../validation/withFormValidation';
import { DropdownProps, Option } from './interfaces';
import classNames from 'classnames';

require('./Dropdown.scss');

function Dropdown({ id, validateData, onChange, label, subLabel, disabled = false, selectProps, initialValue }: DropdownProps) {
  const [selectedOptions, setSelectedOptions] = useState<Option | OptionsType<Option> | null>([]);

  useEffect(() => {
    if (Array.isArray(initialValue)) {
      setSelectedOptions(selectProps.options.filter((option) => initialValue.includes(option.value)));
    } else if (initialValue) {
      setSelectedOptions(selectProps.options.find((option) => option.value === initialValue) || []);
    }
  }, []);

  useEffect(() => {
    validateData(selectedOptions);
    onChange(selectedOptions);
  }, [selectedOptions, onChange, validateData]);

  const modifiers = disabled ? [Spot.modifier.disabled] : [];

  return (
    <div className={'spot-custom-dropdown'}>
      {label && <label className={createSpotClasses(Spot.block.form, Spot.element.label)}>{translate(label)}</label>}
      {subLabel && <span className={createSpotClasses(Spot.block.form, Spot.element.microcopy)}>{translate(subLabel)}</span>}
      <div className={classNames(createSpotClasses(Spot.block.form, Spot.element.input, modifiers), 'spot-custom-dropdown-inner')}>
        <Select
          inputId={`${id}-input`}
          onChange={setSelectedOptions}
          classNamePrefix={'spot-custom-dropdown'}
          isDisabled={disabled}
          value={selectedOptions}
          {...selectProps}
        />
      </div>
    </div>
  );
}

export default withFormValidation(Dropdown);
