/**
 * Generates a spot style list of classes following the BEM CSS convention. Understanding the BEM CSS naming convention
 * will help you in understanding the inputs and outputs of this function, but at a high level this will generate the
 * spot classes you will need to implement any specific variation of a spot element.
 *
 * http://getbem.com/naming/
 *
 * @param {string} block The name of the base class in the style sheet. For spot-link the block would be "link"
 * @param {string} [element] An optional indicator for use in constructing classes for element sub-members.
 *      For example, spot-link__icon would be a spot class for the subElement "icon" of the element "link".
 * @param {string[]} [modifiers] The various modifiers you want to enable on the element. For example if you wanted the
 *      disabled, small, and ellipsis modifiers of the "link" element you would pass ["disabled", "small", "ellipsis"]
 * @returns {string} A string of the following general shape:
 *      "spot-block__element spot-block__element--modifier1 spot-block__element--modifier2 ..."
 *      or
 *      "spot-block spot-block--modifier1 spot-block--modifier2 spot-block--modifier3 ..."
 */
export const createSpotClasses = (block, element, modifiers) => {
    if (!Object.values(Spot.block).find((spotBlock) => spotBlock === block)) {
        throw new Error(`The block "${block}" is not listed in the spot block constants.`);
    }
    let baseClass = `${Spot.spot}-${block}`;
    if (element) {
        if (!Object.values(Spot.element).find((spotElement) => spotElement === element)) {
            throw new Error(`The element "${element}" is not listed in the spot element constants.`);
        }
        baseClass += `__${element}`;
    }
    if (modifiers) {
        return modifiers.filter((modifier) => !!modifier).reduce((classes, modifier) => {
            if (!Object.values(Spot.modifier).find((spotMod) => spotMod === modifier)) {
                throw new Error(`The modifier "${modifier}" is not listed in the spot modifier constants.`);
            }
            return `${classes} ${baseClass}--${modifier}`;
        }, baseClass);
    } else {
        return baseClass;
    }
};

//A list of elements used to create spot strings rather than hard coding them throughout the app.
export const Spot = {
    spot: 'spot',
    block: {
        button: 'button',
        link: 'link',
        icon: 'icon',
        form: 'form',
        popover: 'popover',
        tabs: 'tabs',
        typography: 'typography',
        patientDisplay: 'patient-display',
        toggle: 'toggle',
        toggleLabel: 'toggle-label',
        searchBar: 'search-bar',
        loading: 'loading-spinner'
    },
    element: {
        icon: 'icon',
        input: 'input',
        heading: 'heading',
        fieldGroup: 'field-group',
        fieldError: 'field-error',
        fieldErrorText: 'field-error-text',
        fieldset: 'fieldset',
        label: 'label',
        link: 'link',
        linkText: 'link-text',
        list: 'list',
        listWrap: 'list-wrap',
        legend: 'legend',
        microcopy: 'microcopy',
        tab: 'tab',
        text: 'text',
        textarea: 'textarea',
        checkbox: 'checkbox',
        select: 'select',
        option: 'option',
        searchButton: 'search-button',
        scrollButton: 'scroll-button'
    },
    modifier: {
        active: 'active',
        ellipsis: 'ellipsis',
        disabled: 'disabled',
        level1: 'level-1',
        level2: 'level-2',
        level3: 'level-3',
        level4: 'level-4',
        level5: 'level-5',
        large: 'large',
        left: 'left',
        autoPosition: 'auto-position',
        bottomCenter: 'bottom-center',
        topCenter: 'top-center',
        bottomLeft: 'bottom-left',
        topLeft: 'top-left',
        shown: 'shown',
        horizontal: 'horizontal',
        scrollable: 'scrollable',
        showVisited: 'show-visited',
        small: 'small',
        medium: 'medium',
        right: 'right',
        primary: 'primary',
        secondary: 'secondary',
        sales: 'sales',
        iconOnly: 'icon-only',
        withIcon: 'with-icon',
        next: 'next',
        previous: 'previous',
        responsive: 'responsive',
        nextScrollHidden: 'next-scroll-hidden',
        previousScrollHidden: 'previous-scroll-hidden'
    },
    icons: {
        add: 'add',
        cancel: 'cancel',
        next: 'next',
        caretDown: 'caret-down',
        communicate: 'communicate',
        calendar: 'calendar',
        paperclip: 'paperclip',
        inbox: 'inbox',
        spinner: 'spinner',
        checkmark: 'checkmark',
        thumbtack: 'pin-2',
        checkboxIndeterminate: 'checkbox-indeterminate',
        animalFeline: 'animal-feline',
        animalRat: 'animal-rat',
        animalRabbit: 'animal-rabbit',
        animalAvian: 'animal-avian',
        animalCanine: 'animal-canine',
        back: 'back',
        search: 'search',
        previous: 'previous',
        delete: 'delete'
    }
};
