import React from 'react';
import { PracticeModel } from '../../services/http/practiceConnect/practice/practiceModel';

interface DataContext {
  setContactFirstName: (name: string) => void;
  contactFirstName: string;
  setContactLastName: (name: string) => void;
  contactLastName: string;
  setContactPosition: (position: string) => void;
  contactPosition: string;
  setContactEmail: (email: string) => void;
  contactEmail: string;
  setContactPhone: (phone: string) => void;
  contactPhone: string;
  setEulaContactFirstName: (firstName: string) => void;
  eulaContactFirstName: string;
  setEulaContactLastName: (lastName: string) => void;
  eulaContactLastName: string;
  setEulaContactPosition: (position: string) => void;
  eulaContactPosition: string;
  setEulaContactPhoneNumber: (phoneNUmber: string) => void;
  eulaContactPhoneNumber: string;
  setEulaContactEmail: (email: string) => void;
  eulaContactEmail: string;
  setEulaContactBusinessDecisions: (checked: boolean) => void;
  eulaContactBusinessDecisions: boolean;
  setEulaContactReadEula: (checked: boolean) => void;
  eulaContactReadEula: boolean;
  setSelectedPractice: (practice: PracticeModel) => void;
  selectedPractice: PracticeModel;
  setSapId: (sapId: string) => void;
  sapId: string;
  setPims: (pims: string) => void;
  pims: string;
}

export const EnrollmentDataContext = React.createContext<DataContext>({
  setContactFirstName: (): void => void 0,
  contactFirstName: '',
  setContactLastName: (): void => void 0,
  contactLastName: '',
  setContactPosition: (): void => void 0,
  contactPosition: '',
  setContactEmail: (): void => void 0,
  contactEmail: '',
  setContactPhone: (): void => void 0,
  contactPhone: '',
  setEulaContactFirstName: (): void => void 0,
  eulaContactFirstName: '',
  setEulaContactLastName: (): void => void 0,
  eulaContactLastName: '',
  setEulaContactPosition: (): void => void 0,
  eulaContactPosition: '',
  setEulaContactPhoneNumber: (): void => void 0,
  eulaContactPhoneNumber: '',
  setEulaContactEmail: (): void => void 0,
  eulaContactEmail: '',
  setEulaContactBusinessDecisions: (): void => void 0,
  eulaContactBusinessDecisions: false,
  setEulaContactReadEula: (): void => void 0,
  eulaContactReadEula: false,
  setSelectedPractice: (): void => void 0,
  selectedPractice: { name: '', city: '', address1: '', address2: '', region: '', country: '', zip: '' },
  setSapId: (): void => void 0,
  sapId: '',
  setPims: (): void => void 0,
  pims: '',
});
