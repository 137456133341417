import React, { useContext, useState } from 'react';
import translate from 'counterpart';
import constants from '../../../utils/constants';
import { LoggedInUserContext } from '../../contexts/LoggedInUserContext';
import { onboardUser } from '../../../services/http/practiceConnect/user/userService';
import { Spot, SpotIcon } from '../../../Spot';
import { getCurrentApplication } from '../../../utils/application';
import { ConfigContext } from '../../contexts/ConfigContext';
require('./EnrollmentLeftPane.scss');

function EnrollmentLeftPane() {
  const [isSkipping, setIsSkipping] = useState(false);
  const { loggedInUser } = useContext(LoggedInUserContext);
  const { canSkipEnrollment } = useContext(ConfigContext);

  const onSkip = async () => {
    if (!isSkipping) {
      setIsSkipping(true);
      const application = getCurrentApplication();
      try {
        await onboardUser(loggedInUser!.email, application, []);
        window.location.href = constants.ENTERPRISE_REDIRECT_URL;
      } catch (err) {
        setIsSkipping(false);
      }
    }
  };

  return (
    <div className={'enrollment-left-pane-container'} data-automation-id={'enrollmentLeftPaneContainer'}>
      <div className={'enrollment-left-pane-top'}>
        {/* TODO: This is removed until we have a valid way to pull the first name of the user.*/}
        {/*<div className={'enrollment-left-pane-welcome'} data-automation-id={'enrollmentLeftPaneContainerWelcomeText'}>*/}
        {/*    {`${translate('welcome')} Mark Newman`}*/}
        {/*</div>*/}
        <div className={'enrollment-left-pane-get-started'}>{translate('getStartedByConnecting')}</div>

        {canSkipEnrollment && (
          <div className={'enrollment-left-pane-skip'}>
            {!isSkipping && <a onClick={onSkip}>{translate('skipForNow')}</a>}
            {isSkipping && (
              <SpotIcon
                title={'spinner'}
                iconName={Spot.icons.spinner}
                data-automation-id={'findingPracticeLoadingSpinner'}
                className={'spot-loading-spinner spot-loading-spinner--large'}
              />
            )}
          </div>
        )}
      </div>
      <div className={'enrollment-left-pane-need-help'}>
        {translate('needHelpWithConnection')}
        <div className={'enrollment-left-pane-phone-number'}>
          <a href={constants.SUPPORT_PHONE_HREF}>{constants.SUPPORT_PHONE_TEXT}</a>
        </div>
      </div>
    </div>
  );
}

export default EnrollmentLeftPane;
