import React from 'react';
import translate from 'counterpart';
import { SpotIcon, Spot } from '../../../../../../Spot';
import EnrollmentRightPaneFooter from '../../../EnrollmentRightPaneFooter/EnrollmentRightPaneFooter';
require('./EnrollmentEulaSubmitPage.scss');

function EnrollmentEulaSubmitPage() {
  return (
    <div className={'enrollment-submit-eula-container'}>
      <div className={'enrollment-submit-eula-text'}>
        <div className={'searching-content-title'} data-automation-id={'enrollmentSubmitEulaText'}>
          {translate('eulaSubmitText')}
        </div>
        <div className={'searching-spinner'}>
          <SpotIcon
            title={'spinner'}
            iconName={Spot.icons.spinner}
            data-automation-id={'eulaSubmitSpinner'}
            className={'spot-loading-spinner spot-loading-spinner--large'}
          />
        </div>
      </div>
      <div className={'enrollment-submit-eula-footer'}>
        <EnrollmentRightPaneFooter />
      </div>
    </div>
  );
}

export default EnrollmentEulaSubmitPage;
