import React from 'react';
import './Cancel.scss';

export default function CancelIcon({ fill }: { fill?: string }): JSX.Element {
  return (
    <>
      <svg className={'cancel-icon'} width="32" height="32" fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <title>Cancel</title>
        <path d="M24.4 1.8L16 10.4 7.6 1.8 1.8 7.6l8.6 8.4-8.6 8.4 5.8 5.8 8.4-8.6 8.4 8.6 5.8-5.8-8.6-8.4 8.6-8.4z" />
      </svg>
    </>
  );
}
