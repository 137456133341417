import React from 'react';
require('./Close.scss');
/**
 * HeaderLogo is a colored SVG IDEXX Logo of 100 pixel width.
 * @returns {*}
 */
export default function CloseIcon({ fill }: any) {
  return (
    <div className={'close-box-container'}>
      <svg className={'close-icon'} width="32" height="32" fill={fill} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path d="M16.07 2c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14-6.3-14-14-14zm6.124 17.675l-2.45 2.45-3.675-3.675-3.675 3.675-2.45-2.45L13.62 16l-3.675-3.675 2.45-2.45 3.675 3.675 3.675-3.675 2.45 2.45L18.52 16l3.675 3.675z" />
      </svg>
    </div>
  );
}
