import React from 'react';
import IdexxLogo from '../../../images/IdexxLogo/IdexxLogo';
require('./EnrollmentHeader.scss');

function EnrollmentHeader() {
  return (
    <div className={'enrollment-header-container'}>
      <div className={'enrollment-header-logo'}>
        <a href={'/messages'}>
          <IdexxLogo />
        </a>
      </div>
    </div>
  );
}

export default EnrollmentHeader;
