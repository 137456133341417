import React from 'react';
import { UserModel } from '../../services/http/practiceConnect/user/userModel';

export const LoggedInUserContext = React.createContext({
  setLoggedInUser: {},
  logOutUser: (): void => void 0,
  loginUrl: '',
  loggedInUser: {} as UserModel | null,
  error: false,
});
